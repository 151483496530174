.profile-content-wrapper {
    padding: 30px 0 0;
}

.profile-header {
    display: flex;
    align-items: center;
    padding-left: 0;
    padding-right: 15px;
    &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        width: 99%;
        height: 1px;
        background-color: $grey;
    }
    @include mq($bs-tablet) {
        padding-right: 30px;
    }
    @include mq(639px) {
        flex-wrap: wrap;
        justify-content: center;
        padding-right: 0;
        padding-bottom: 30px;
    } 
}
.profile-header-title {
    font-size: 67px;
    color: $grey;
    @include mq(1199px) {
        font-size: 42px;
        line-height: 1;
        margin-right: 15px;
        margin-bottom: 20px;
        > span {
            display: block;
        }
    }
    @include mq(700px) {
        font-size: 36px;
        margin: 15px;
        line-height: 1.3;
    }
}
.profile-header-options {
    display: flex;
    margin-left: auto;
    @include mq(639px) {
        margin-left: 15px;
    }
}
.profile-header-options-icons-wrapper {
    display: flex;
    margin-right: 85px;
    @include mq($bs-tablet) {
        margin-right: 15px;
    }
}
.profile-header-options-icon {
    position: relative;
    align-self: center;
    margin-right: 15px;
    &:last-child {
        margin-right: 0;
    }
}
.profile-header-options-icon-notification {
    display: block;
    position: absolute;
    top: 0;
    right: -5px;
    width: 22px;
    height: 22px;
    background-color: $red;
    color: $white;
    border-radius: 50%;
    font-style: normal;
    text-align: center;
    line-height: 22px;
}
.profile-btn {
    align-self: center;
    text-transform: uppercase;
    color: $white;
    background-color: $sky-blue;
    border-radius: 30px;
    padding: 15px 40px;
    border: 1px solid $grey;
    font-size: 13px;
    white-space: nowrap;
    transition: $dur $ease;
    &:hover {
        background-color: $blue;
        color: $white;
    }
}
.profile-content-main-row {
    margin-bottom: 30px;
    @include mq(1199px) {
        
        margin: 0 auto 30px;
    }
}
.profile-content-main-info {
    display: flex;
    min-height: 270px;
    background-color: $white;
    @include mq($bs-tablet) {
        margin-bottom: 40px;
    }
}
.profile-content-main-info-content {
    display: flex;
    align-self: center;
    width: 100%;
    padding: 0 30px;
}
.profile-content-main-info-content-text {
    font-size: 20px;
    font-weight: bold;
    align-self: center;
    text-align: center;
}
.profile-content-main-info-content-number {
    margin-left: auto;
    color: $sky-blue;
    font-size: 50px;
}
.profile-content-main-list {
   
}
.profile-content-main-list-item {
    display: flex;
    max-width: 315px;
    margin: 0 auto 10px;
    padding: 15px 30px;
    @include mq(767px) {
        max-width: 100%;
    }
}
.profile-content-main-list-item--item {
    background-color: $grey;
    border-radius: 4px;
}
.profile-content-main-list-item--item-blue {
    background-color: $blue;
    color: $white;
}
.profile-content-main-list-number {
    margin-left: auto;
}
.profile-content-main-btn {
    display: block;
    background-color: $sky-blue;
    color: $white;
    border-radius: 10px;
    text-align: center;
    transition: $dur $ease;
    &:hover {
        color: $white;
        background-color: $blue;
    }
}


// LEFT SIDE 

.profile-content-statistics-title {
    font-size: 20px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 20px;
}
.profile-content-statistics-box {
    margin-bottom: 55px;
}

.profile-content-statistics-scroll-box-header {
    display: block;
    font-size: 15px;
    background-color: $blue;
    color: white;
    width: 100%;
    padding: 20px;
    border-radius: 2px;
}
.profile-content-statistics-scroll-box {
    overflow-y: scroll;
    max-height: 160px;
    padding: 20px;
    background-color: $white;
    /* width */
    &::-webkit-scrollbar {
        width: 15px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background-color: $blue-light;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $blue; 
        border-radius: 10px;
    }

    /* Handle on hover */
    // &::-webkit-scrollbar-thumb:hover {
    //     background-color: $sky-blue;
    // }
}
.profile-content-statistics-scroll-box-row {
    display: flex;
    padding-bottom: 10px;
}
.scroll-box-row-text, .scroll-box-row-text-value {
    width: 50%;
    align-self: center;
}
.scroll-box-total-text--big {
    text-transform: uppercase;
    font-weight: bold;
}

.scroll-box-row-text-value {
    background-color: $grey-light;
    max-width: 105px;
    padding: 8px 10px;
    
}
.profile-content-statistics-scroll-box-total {
    display: flex;
    background-color: $grey;
    padding: 17px 25px;
}
.scroll-box-row-value-total {
    padding: 0 10px;
    font-weight: bold;
}
.profile-content-statistics-revision {
    min-height: 500px;
}
.profile-content-statistics-revision-content {
    background-color: $white;
    padding: 40px 25px;
}
.statistics-revision-content-img-wrapper {
    display: flex;
}
.statistics-revision-text__wrap {
    margin-left: 15px;
    margin-bottom: 10px;
}
.statistics-revision-content-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        margin-top: 5px;
        background-color: #1368b1;
    }
    &:last-child {
        &::after {
            display: none;
        }
    }
}
.statistics-revision-text {
    display: block;
    font-size: 12px;
    color: $grey-dark;
    margin-top: -3px;
}
.revision-text-content-btn {
    display: inline-block;
    font-size: 12px;
    transition: $dur $ease;
    margin-left: auto;
    &:hover {
        color: $sky-blue;
    }
}
.slick-prev-arrow, .slick-next-arrow {
    background-color: $sky-blue;
    position: absolute;
    top: -60px;
    right: 0;
    border-radius: 0;
    padding: 15px;
}
.slick-prev-arrow {
    right: 45px;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        height: 25px;
        width: 1px;
        background-color: $white;
        z-index: 9;

    }
}
.slick-next-arrow {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
}
.slick-btn-icon {
    color: $white;
}
.profile-img {
    max-width: 400px;
    img {
        display: block;
        height: initial;
        max-width: 100%;
    }
}
.delete-user-profile {
    position: relative;
}
.btn-question {
    position: absolute;
}
