.datepicker{
    position: relative; 
    background:#1468b1;
    color: $white !important;
    font-size: 12px;
    &::placeholder {
        color:$white;
        opacity: 1;
    }
    i::before {
        color: $white;
    }
}    
.emptyTour {
    & [class*=' col-'] {
        padding: 0 7px;
    }
    .datepicker{
        position: relative; 
        z-index: 100000;
        background:#1468B1;
        color:white;
        &::placeholder{
            color:white;
            opacity: 1;
        }
    }    
    input{
        @include mq(760px) {
            margin-bottom:10px;
        }
    }
    .glyphicon {
        position:absolute;
        right: 10px;
        color:white;
        z-index: 100001;
    }
    select{
        background:#1368b1;
        color:white !important;
    }
    h2{
        font-size:67px;
        line-height:148px;
        color:#cdd1dc;
        font-weight:normal !important;
        
        @include mq(991px) {
            font-size:37px;
            line-height:normal;
        }
    }
    label{
        font-size:20px;
        color:black;
        font-weight:bold;
    }
    h3{
        font-size:30px;
        line-height:0px;
        padding:20px;
        margin:0px;
    }
    .first{
        margin-bottom:20px;
        .left{
            .well{
                border:none;
                box-shadow:none;
                margin-bottom:0px;
                background:#E7EAF3;
                
                &:nth-of-type(1){
                    background:#1468B1;
                    color:white;
                }
                .row{
                    margin-bottom:15px;
                }
            }
        }
        .right{
            .well{
                border:none;
                box-shadow:none;
                margin-bottom:0px;
                background:#E7EAF3;
                
                &:nth-of-type(1){
                    background:#1468B1;
                    color:white;
                }
                .row{
                    margin-bottom:15px;
                }
            }
        }
    }
    
    .sec{
        .well{
            border:none;
            box-shadow:none;
            background:#1468B1;
            color:white;
            
        }
        @include mq(991px) {
            margin-bottom:20px;
        }
    }
    
    .th{
        .left{
            .well{
                border:none;
                box-shadow:none;
                background:#E7EAF3;
                margin-bottom:20px;
                padding:40px;
                @include mq(991px) {
                    padding-bottom:10px;
                    height:auto;
                }
               
                .row{
                    margin-bottom: 15px;
                    margin-top: 15px;
                    height:100%;
                    .col-md-6{
                        height:100%;
                        @include mq(991px) {
                            margin-bottom:20px;
                        }
                    }
                }
            }
        }
        .right{
            .well{
                border:none;
                box-shadow:none;
                margin-bottom:20px;
                padding:0px;padding:40px;
                @include mq(991px) {
                    padding-bottom:10px;
                    height:auto;
                }
                
                @include mq(639px) {
                    padding:0px;
                }
                .row{
                    margin-bottom:15px;
                }
            }
        }
    }
    
    .bt{
        button{
            background: #15BEC5;
            color: white !important;
            border-radius: 75px;
            padding: 13px 30px 13px 30px !important;
            font-weight: bold;
            cursor: pointer !important;
            margin-right:30px;
        }
    }
}

.driver-info-bottom {
    display: flex;
    align-items: flex-start !important;
    label {
        padding-top: 10px;
    }
    textarea {
        padding: 15px;
    }
}

.form-box {
    background-color: $grey-lighter;
    margin-bottom: 20px;
    min-height: 238px;
}
.form-box-head {
    background-color: $checkbox-radio;
    min-height: 20px;
    padding: 19px;
    border-radius: 4px;
    color: $white;
}
.form-box-body {
    padding: 15px 10px;
}
.form-box-row {
    display: flex;
    justify-content: space-evenly;
    > * {
        flex-basis: 49%;
        padding: 7px;
    }
    @include mq(640px) {
        flex-direction: column;
    }
}
.form-box-label {
    display: flex;
    align-items: center;
    label {
        margin-bottom: 0;
    }
}
.form-box-input {
    position: relative;
    display: flex;
}
.form-box-input-half {
    display: flex;
    input {
        flex-basis: 49%;
        &:first-child {
            margin-right: 14px;
        }
    }
}
.form-box-input-street {
    .form-box-input {
        display: flex;
        input:last-child {
            width: 30%;
            margin-left: 14px;
        }
    }
}
.form-box-input-icon {
    margin-left: 10px;
    font-weight: bold;
    font-size: 20px;
}
.form-box-input-photo {
    display: flex;
    flex-direction: column;
    width: 50%;
    .btn-bs-file {
        display: block;
        max-height: 50px;
        max-width: 200px;
        margin: 0;
    }
}
.form-box-input-img-wrap {
    display: none;
    position: relative;
    margin: 15px;
}