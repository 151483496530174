.section-transfer {
    margin-bottom: 10px;
    @include mq(992px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    @include mq(569px) {
        flex-wrap: wrap;
        margin-bottom: 0;
        .row {
            margin: 0 0 15px;
        }
    }
}
.transfer-table-header {
    background-color: $blue;
    color: $white;
    padding: 15px 0;
    margin-bottom: 15px;
    .row {
        display: flex;
        align-items: center;
        div {
            position: relative;
            text-align: center;
        }
    }
    @include mq(992px) {
        display: none;
    }
}
.table-transfer-row {
    @include mq(992px) {
        flex-direction: column;
        width: 50%;
        margin-bottom: 15px;
        div {
            width: 100%;
            margin: 5px 0;
        }
        .text-left {
            text-align: center;
        }
    }
    @include mq(569px) {
        width: 100%;
    }
}
.table-transfer-col {
    white-space: nowrap;
}
.table-mob-label {
    display: none;
    @include mq(992px) {
        display: inline-block;
        margin-right: 10px;
    }
}

.btn--table {
    border-radius:20px;
    background: #001F5D;
    color: white;
    border: none;
    padding-top: 7px;
    padding-right: 15px;
    padding-bottom: 7px;
    padding-left: 15px;
    white-space: nowrap;
}
.bus-status {
    display: block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #bf5329;
    margin: 0 auto;
}
.bus-status--active {
    background-color: #2ab27b;
}
.ui-state-active {
    background-color: $sky-blue !important;
}