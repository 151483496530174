
.rowMargin{
  margin-bottom: 20px;
}
.firstForm{
   label{
      padding-top: 8px;
      color: #001F5C;
      font-weight: normal;
   }
}
.buses-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.firstForm-content-wrapper {
  @include mq(1703px) {
    width: 100%;
  }
}
.wellSilver{
   background: #CDD1DC;
   label{
      padding-top: 4px;
      color: black;
   }
}
.wellDarkSilver{
   background:#CDD1DC;
   label{
      padding-top: 5px;
      color: #001F5C;
   }

  .row-caffe {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .col-md-2 {
      position: initial;

      span {
        position: absolute;
        display: flex;
        top: 100%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
  }
   .divide{
      margin-bottom: 20px;
      margin-top: 20px;
      border: 0;
      height: 1px;
      background: #333;
      background-image: -webkit-linear-gradient(left, #ccc, #333, #ccc);
      background-image: -moz-linear-gradient(left, #ccc, #333, #ccc);
      background-image: -ms-linear-gradient(left, #ccc, #333, #ccc);
      background-image: -o-linear-gradient(left, #ccc, #333, #ccc);
   }

  &.ski-box {

    .row {
      .alertValSpan {
        position: absolute;
        bottom: -20px;
      }
    }

    .col-md-6 {
      @include mq(991px) {
        margin-bottom: 15px;
      }

      label {
        width: 35%;
      }

      input {
        width: 60%;
      }

      span {
        margin-left: 3%;
      }
    }

    .col-md-5 {
      display: flex;
      align-items: center;
      position: relative;
      justify-content: center;

      label {
        width: 35%;
      }

      input {
        width: 60%;
      }
    }

    .col-md-1 {
      display: flex;
      align-items: center;

      label {
        margin: 0;
      }
    }
  }
}
div[data-tip="prikolicaBicikli"]{
  display: none;
}
div[data-tip="velikaPrikolica"]{
  display: none;
}
div[data-tip="malaPrikolica"]{
  display: none;
}
div[data-tip="skiBox"]{
  display: none;
}
div[data-tip="kafeMasina"]{
  display: none;
}
div[data-tip="wlan"]{
  display: none;
}
div[data-tip="airport_check"]{
  display: none;
}
div[data-tip="sight_seeing"]{
  display: none;
}
div[data-tip="bus_pausal"]{
  display: none;
}
div[data-tip="transfer_satu"]{
  display: none;
}

.thumb {
  height: 100%;
  
  border: 1px solid #000;
}

.upload-image-create-bus {
  position: relative;
  max-width: 200px;
  height: 40px;
  white-space: nowrap;
  cursor: pointer;
  input {
    width: 100%;
    opacity: 0;
    position: absolute;
    z-index: 9;
    cursor: pointer;

    &.block {
      z-index: -1;
    }
  }

  span {
    display: flex;
    width: 100%;
    position: absolute;
    cursor: pointer;
    background: $sky-blue;
    border-radius: 30px;
    color: $white;
    text-transform: uppercase;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    padding: 7px;
  }

  .alertValSpan {
    position: relative;
    background: none;
    color: red;
    left: 50%;
    top: 50%;
    transform: translate(50%,-50%);
    display: flex;
    align-items: center;

  }

  &.single {

    .alertValSpan {
      font-size: 12px;
      display: flex;
      align-items: center;
      margin: 0;
      top: auto;
      left: auto;
      bottom: -30px;
      transform: translate(0);
      text-transform: none;
      color: $text-color;
      font-weight: normal;
      position: relative;
    }

  }
}

output {
    .rotate-img-bus {
        position: absolute;
        left: 0;
    }
}

.upload-image-output {
  display: flex;
  flex-flow: wrap;
  margin-top: 20px;
  align-items: flex-start;

  span {
    position: relative;
    display: flex;
    margin-right: 30px;
    margin-bottom: 15px;

    a {
      display: flex;
      align-items: flex-start;
      width: 100%;
    }

    i {
      position: absolute;
      top: 0;
      right: 0;
      background: $sky-blue;
      padding: 5px;
      border-radius: 50%;
      color: $white;
      transform: translate(50%,-50%);
      cursor: pointer;
    }

    img {
      margin: 0;
      max-width: 100%;
      width: 150px;
      height: auto;
    }
  }
}
.upload-image-output--inline {
  margin-top: 0;
  .imagesUploaded {
    margin: 0 auto;
  }
}

.edit-bus-form {

  .save-bus-btn {
    margin-left: 15px;
    // color: $sky-blue;
  }

  textarea {
    resize: none;
  }
}
.btn-inline-wrapper {
  display: flex;
  flex-wrap: wrap;
}
span.glyphicon.glyphicon-star-empty {
  display: none;
}

.well-busses-type {
  border-bottom: 2px solid $white;
}

.label-currency {
  line-height: 37px;
}
.form-control-wrapper {
  display: flex;
  input {
    margin-right: 10px;
  }
}
.ski-wrapper {
  margin: 0;
  label {
    white-space: nowrap;
  }
}
.ski-wrapper-col {
  padding: 0;
}
.info-icon-wrapper {
  display: flex;
  height: 36px;
  padding: 0 5px 0 0;
  a {
    align-self: center;
  }
}

.time-picker-wrapper {
  display: flex;
  @include mq(1199px) {
    margin-bottom: 20px;
  }
}
.time-picker-item {
  width: 33%;
  display: flex;
  @include mq(1520px) {
    flex-wrap: wrap;
  }
}
.time-picker-label, .time-picker {
  align-self: center;
}

.time-picker {
  width: 65%;
}


.upload-image-create-bus {
  @include mq(1200px) {
    margin-top: 30px;
  }
}
.row-oprema {
  margin-bottom: 10px;
  @include mq(640px) {
    flex-wrap: wrap;
  }
  @include mq(450px) {
    width: 100%;
  }
  select {
    font-size: 14px;
    padding: 0;
  }
}
.input-btn-wrapper {
  @include mq(600px) {
    padding: 0;
  }
}
.input-time {
  min-width: 60px;
}
.oprema-input {
  @include mq(1500px) {
    // padding: 0 5px 0 0;
  }
}

.oprema-duration-input {
  input {
    @include mq(1500px) {
      padding: 6px;
    }
  }
}
.disabled-input {
  align-self: center;
}
.flexRow-wrapper {
  
  @include mq(1200px) {
    margin-bottom: 15px;
  }
}
.flexRow {
  display: flex;
  div {
    align-self: center;
  }
  label {
    padding: 0;
    margin: 0;
  }
}
.datepicker-wrapper {
  position: relative;
  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    right: 25px;
    z-index: 2;
    &::before {
      color: $white;
    }
  }
  .alert-text {
    right: initial;
  }
  // @include mq(1600px) {
  //   i {
  //     right: 7.5px;
  //   }
  // }
}
.datepicker-label-wrapper {
  // padding-right: 0;
}
.cena-input-wrapper {
  @include mq(1450px) {
    width: 55%;
  }
}
.add-transfer-wrapper {
  button {
    background-color: #16bdc5;
    font-weight: bold;
    padding: 0px 10px 0px 10px;
    font-size: 20px;
    height: 36px;
  }
}
.date-picker-row {
  display: flex;
  @include mq(1199px) {
    flex-wrap: wrap;
  }
}
.btn-wrapper {
  @include mq(1550px) {
    padding: 0;
  }
}
.price-row {
  @include mq(640px) {
    flex-wrap: wrap;
    .col-lg-4 {
      width: 100%;
      margin-bottom: 15px;
    }
  }
}
.add-new-section-btn-wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 15px;
  .input-group-btn {
    margin-right: 35px;
  }
  .info-icon-wrapper {
    padding-top: 3px;
  }
  .tooltip-wrap {
    margin-right: 60px;
  }
  @include mq(1199px) {
    justify-content: flex-start;
    margin-left: 15px;
    .input-group-btn {
      margin-right: 35px;
    }
  }
}
.has-white-stripe {
  width: 100%;
  &::after {
    content: '';
    display: block;
    width: 94%;
    margin: 0 auto 30px;
    height: 2px;
    background-color: $white;
  }
}
.hidden-box {
  .info-icon-wrapper {
    padding-top: 5px;
  }
  @include mq(1200px) {
    padding: 15px 0;
  }
}
.hidden-section-name {
  font-size: 24px;
  padding-left: 15px;
  margin-bottom: 10px;
}
.hidden-section-name--big {
  color: $blue-dark;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  padding-left: 15px;
  margin-bottom: 20px;
}
.ui-datepicker {
  z-index: 99999999999 !important;
}
.btn-add-price {
  padding-top: 5px;
  @include mq(1300px) {
    padding-left: 0;
  }
}
.internal-id-margins {
  margin: 5px 0;
}

.panel-active {
  display: block;
}
.panel-title {
  transition: $dur $ease;
  &:hover {
    color: $blue-dark;
  }
}
.stars-wrap {
  position: relative;
  display: flex;
  height: 35px;
  align-items: center;
  .glyphicon:hover {
    cursor: pointer;
  }
}
// .stars-wrap-disable {
//   &::after {
//     content: '';
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     right: 0;
//     left: 0;
//     z-index: 5;
//     background-color: transparent;
//   }
// }

