.bus-single-ni {
    margin-top: -45px;

    .well {
        border: none;
        box-shadow: none;
        background: #F2F4F8;

        p {
            margin: 0;
        }
    }

    .first-row {
        margin-bottom: 20px;

        .col-md-3 {
            padding-right: 0px;
        }

        .col-md-2 {
            padding-left: 0px;

            @include mq(991px) {
                padding-left: 15px;
            }
        }

        img {
            width: 100%;

            @include mq(991px) {
                height: auto;
            }
        }

        .first-div {
            @include mq(991px) {
                height: auto;
                padding-right: 15px;
                float: none;
            }

            .well {
                //background:#F2F4F8;
                margin-bottom: 0px;
                border: none;
                box-shadow: none;
                padding: 20px;

                @include mq(991px) {
                    height: auto;
                }

                h2 {
                    font-size: 25px;
                    font-weight: bold;
                    margin-bottom: 15px;

                }

                p {
                    font-size: 14px;
                    color: black;
                }
            }
        }

        .sec-div {
            .col-md-12 {
                height: 120px;
                padding: 0px;

                @include mq(991px) {
                    padding: 0px 15px 0px 15px;
                }

                &:nth-child(1) {
                    margin-bottom: 20px;
                }

                &:nth-child(2) {
                    margin-bottom: 20px;
                }
            }

            @media screen and (max-width: 767px) {
                display: flex;
                flex-wrap: wrap;
                height: initial;

                > div {
                    height: initial !important;
                }
            }
        }

        @include mq(991px) {
            height: auto;
        }
    }


    .sec-row {
        h2 {
            font-size: 20px;
            font-weight: bold;
            padding-left: 30px;
            margin-bottom: 30px;

            @include mq(991px) {
                text-align: center;
            }
        }

        .col-md-2 {
            padding: 0px;

            ul {
                list-style-type: none;
                margin: auto !important;
                padding: 0px;
                color: black;

                li {
                    padding: 5px;
                }
            }

            &:nth-of-type(3) {
                margin-left: 20px;
                margin-right: 20px;

                @include mq(770px) {
                    margin-left: 0px;
                    margin-right: 0px;
                }
            }

            &:nth-of-type(4) {
                margin-right: 20px;

                @include mq(770px) {
                    margin-right: 0px;
                }
            }

            @include mq(991px) {
                margin-bottom: 20px;
                text-align: center;
            }
        }

    }


    .th-row {
        h2 {
            font-size: 20px;
            font-weight: bold;
            padding-left: 30px;
            margin-bottom: 30px;

            @include mq(991px) {
                text-align: center;
            }
        }

        p {
            padding: 30px;
            color: black;
            line-height: 30px;
        }
    }


    .fo-row {
        h2 {
            font-size: 20px;
            font-weight: bold;
            padding-left: 30px;
            margin-bottom: 30px;
        }

        img {
            margin-right: 20px;
            margin-bottom: 5px;
        }

        p {
            &:nth-of-type(1) {
                font-size: 20px;
                font-weight: bold;
                margin-right: 20px;
                margin-bottom: 0px;
            }

            &:nth-of-type(2) {
                color: black;
                line-height: 30px;
            }
        }

        .row {
            position: relative;

            h1 {
                // position:absolute;
                // top:40%;
                // right:0%;
                // transform:translate(-50%,-50%);
                width: 70px;
                font-weight: bold;

                @include mq(991px) {
                    top: 0%;
                    right: 0%;
                }
            }
        }
    }


    .fi-row {
        h2 {
            font-size: 20px;
            font-weight: bold;
            padding-left: 30px;
            margin-bottom: 30px;
        }

        .first {
            .col-md-6 {
                padding-left: 25px;

                .well {
                    background: #CCD1DC;
                    /*height:200px;*/
                }
            }
        }

        .sec {
            .col-md-6 {
                padding-left: 25px;

                .well {
                    background: #CCD1DC;
                    /*height:200px;*/
                }
            }
        }
    }
}

.label-check {
    position: relative;
}

.form-section-title {
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 700;
}

.pass-send-input-wrap {
    margin-bottom: 15px;
}

.form-row-label {
    font-size: 18px;
    color: $grey-shade;
}

.bus-single-list {
    list-style: none;
    padding: 0;
    width: (100% / 12 * 10);
    margin: 0 auto;

    @media screen and (max-width: 767px) {
        width: 100%;
    }
}

.bus-single-list-item {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 12px;
    color: #585858;
    display: inline-block;
    letter-spacing: -0.02em;

    &::first-letter {
        text-transform: uppercase;
    }

    @media screen and (max-width: 767px) {
        font-size: 14px;
    }
}

.bus-single-list-item__dropdown {
    position: absolute;
    display: none;
    background-color: #F3F4F8;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    z-index: 1;

    li {
        padding-left: 15px;
    }
}

.separate-after.separate-after.separate-after {
    margin-bottom: 1em;
}

.dropdown-triangle {
    position: absolute;
    top: 9px;
    right: 30%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7.5px 7.5px 0 7.5px;
    border-color: $blue-dark transparent transparent transparent;

    @include mq(991px) {
        right: 15px;
    }
}

.icon-thumbs {
    display: inline-block;
    margin-right: 5px;
}

.comment {
    margin-bottom: 30px;

    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: $blue;
        margin-top: 30px;
    }

    &:last-child {
        margin-bottom: 0;

        &::after {
            background-color: transparent;
            margin-bottom: 0;
        }
    }
}

.comment-header {
    margin-bottom: 10px;

}



// .slider-bus-tour-new-container {
//     @include mq(767px) {
//         >h2 {
//             margin-bottom: 60px;
//         }
//     }
// }
