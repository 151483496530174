.autocomplite{
    position:absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index:99999;
    background:white;
    width:100%;
    margin-bottom:-15px;
    display:none;
    max-height:300px;
    box-shadow: inset 0 0 5px grey; 
    
    ul{
        list-style-type:none;
        margin:0px;
        padding:0px;
        max-height:300px;
        overflow: auto;
    
        li{
            display:none;
            padding:10px;
            border-top:1px solid #ccc;
        }
        li:hover{
            background:#1368B1;
            cursor:pointer;
            color:white;
        }
        li:first{
            border:none;
        }
        
        .active{
            background:#1368B1;
            color:white;
        }
        
        &::-webkit-scrollbar {
            width: 1em;
        }
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        }
        &::-webkit-scrollbar-thumb {
          background-color: #001F5D;
          outline: 1px solid slategrey;
        }

        
    }
}

.wrap-autocomplite{
    position:relative;
}

.autocomplite2{
    position:absolute;
    width:90.5%;
    height:100px;
    top:28px;
    left:0px;
    background:white;
    color:black;
    display:none;
    z-index:99999;
    max-height:300px;
    
    ul{
        list-style-type:none;
        margin:0px;
        padding:0px;
        max-height:300px;
        overflow: auto;
    
        li{
            display:none;
            padding:10px;
            border-top:1px solid #ccc;
        }
        li:hover{
            background:#1368B1;
            cursor:pointer;
            color:white;
        }
        li:first{
            border:none;
        }
        
        .active{
            background:#1368B1;
            color:white;
        }
        
        &::-webkit-scrollbar {
            width: 1em;
        }
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        }
        &::-webkit-scrollbar-thumb {
          background-color: #001F5D;
          outline: 1px solid slategrey;
        }

        
    }
}
