.flex {
    display: flex;
    label {
        margin-bottom: 0;
    }
}
.flex-row {
    margin-bottom: 15px;
}
.flex-center {
    align-items: center;
}
.flex-subtitle {
    display: block;
    margin-bottom: 15px;
}
.flex-label {
    margin-right: 10px;
    font-size: 16px;
    color: $grey-shade;
}
.flex-text {
    font-size: 16px;
    color: $grey-dark;
    font-weight: bold;
}
.mlr-auto {
    margin-left: auto;
    margin-right: auto;
}
.ml-auto {
    margin-left: auto;
}
.ml-15 {
    margin-left: 15px;
}
.ml-30 {
    margin-left: 30px;
}
.mt-15 {
    margin-top: 15px;
}
.block {
    display: block;
}
.relative {
    position: relative;
}
.btn-tb-margins {
    margin-top: 15px;
    margin-bottom: 15px;
}
.navbar-right {
    margin-top: 27px;
}
.translate-left {
    transform: translateX(-80px);
    padding-top: 7px;
    @include mq(1700px) {
        transform: translateX(0);
    }
}
.row-oprema {
    display: flex;
    flex-wrap: wrap;
    @include mq(767px) {
        flex-direction: column;
    }
}
.row-oprema-item {
    width: 30%;
    margin: 0 0.5% 15px;
}
.row-oprema-item2 {
    line-height: 1.2;
}
.center {
    text-align: center;
}
.text-left {
    text-align: left;
}
.label-blue {
    background-color: $sky-blue;
    color: $white;
    border-radius: 4px;
    padding: 6px 10px;
    white-space: nowrap;
    font-weight: 600;
    margin-left: -10px;
    @include mq(1199px) {
        margin-left: 0;
    }
}
.pagination>li>a, .pagination>li>span {
    transition: $dur $ease;
}
td>a {
    transition: $dur $ease;
}
.rate-bus {
    &:checked {
        display: block !important;
    }
}
.bgc-grey-l {
    background-color: $grey-lighter;
}
.jbt-flex {
    @include mq(767px) {
        flex-wrap: wrap;
    }
}