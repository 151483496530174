/* The Modal (background) */
.modal-tutorial {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 999999;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-tutorial-content {
    background-color: #fefefe;
    margin: 2.5% auto;
    /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 95%;
    /* Could be more or less, depending on screen size */
    max-height: 90vh;
}

/* The Close Button */
.close-tutorial {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    margin-right: 15px;
}

.close-tutorial:hover,
.close-tutorial:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.modal-tutorial-wrap {
    padding: 30px 0;
}

.modal-tutorial-wrap .slick-slide:focus {
    outline: none;
}

.modal-tutorial-wrap .slick-arrow {
    position: absolute;
    top: 0;
}

.modal-tutorial-wrap .slick-prev {
    left: 15px;
}

.modal-tutorial-wrap .slick-next {
    right: 15px;
}

.modal-tutorial-title {
    display: block;
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: bold;
    padding-left: 15px;
}

.modal-tutorial-skip {
    float: right;
    margin-right: 40px;
    margin-left: 20px;
    font-size: 28px;
    font-weight: bold;
    transition: $dur $ease;

    @include mq(1200px) {
        display: block;
        float: none;
        padding-left: 15px;
        margin-left: 0;
    }
}

.modal-tutorial-skip:hover {
    color: #15bec5;
    cursor: pointer;
}

.modal-tutorial__slide {
    padding: 0 15px !important;
}

.modal-tutorial__slide-text {
    width: 100%;
    text-align: center;

    p {
        font-size: 22px;
        line-height: 1.6;
    }
}

.modal-tutorial__slide img {
    max-width: 1720px;
    max-height: 690px;
    margin: 0 auto;

}
