.singletour{
    background-color:#f2f4f8;
    background-image:none !important;

}

.marginminus {
    margin-top: -30px;

    @include mq(991px) {
        width: 100%;
    }

    form {

        @include mq(991px) {
            padding: 0 15px;
            width: 100%;
        }
    }

    // background-color:#f2f4f8;
    // background-image:none !important;

    /////////////////////////////////////////////
    /////////////////////////////////////////////

    .first-row {

        @include mq(991px) {

            .col-md-4 {
                margin-bottom: 50px;
            }

            .col-md-8 {

                .row {

                    .col-md-2 {

                        @include mq(991px) {
                            display: flex;
                            justify-content: space-around;

                            .first-row-small-img {
                                margin: 10px;
                            }
                        }
                    }
                }
            }
        }

        margin-bottom: 20px;

        img {
            margin-left: 5px;
        }

        .well {
            background: white;
            border: 0px;
            padding-bottom: 60px;
        }

        h6 {
            font-size: 15px;
            font-weight: bold;
        }

        label {
            font-size: 15px;
            font-weight: normal;
        }

        .first-row-add {
            color: #000;
        }

        .first-row-price {
            font-size: 13px;
            font-weight: bold;
            background: #15BEC5;
            color: white;
            padding: 3px 15px 3px 15px;
            border-radius: 5px;
        }

        .priceBtn {
            position: absolute;
            bottom: -5px;
            left: 20%;
            height: 40px;
            background: #15BEC5;
            width: 60%;
            text-align: center;

            -webkit-border-radius: 28;
            -moz-border-radius: 28;
            border-radius: 28px;
            padding: 10px 20px 10px 20px;
            text-decoration: none;

            label {
                color: white;
            }
        }

        .first-row-small-img {
            padding-bottom: 10px;
        }

    }

    /////////////////////////////////////////////
    /////////////////////////////////////////////

    .sec-row {

        margin-bottom: 20px;

        .col-md-4 {
            background: #ccd1dc;
            padding: 10px;
            height: 56px;
            h3 {
                margin: 0px;
                font-size: 20px;
                font-weight: bold;
                color: white;
            }
        }
        .col-md-8 {
            padding-left: 0;
            height: 56px;
            input {
                text-align: center;
                height: 56px;
                color: black;
            }

            @include mq(991px) {
                padding: 0;
            }
        }
    }

    //////////////////////////////////////////////
    /////////////////////////////////////////////
    .price-title {
        font-size: 30px;
        font-weight: bold;
        color: black;
    }
    /////////////////////////////////////////////
    /////////////////////////////////////////////
    .therd-row {

        .well {
            background: white;
            border: 0;
            padding: 0;
            text-align: center;
            position: relative;

            .row {
                display: flex;
                align-items: center;
                margin: 0;

                @include mq(991px) {
                    align-items: flex-start;
                    flex-flow: wrap;
                    padding: 15px 0 0;
                }

                .therd-row-custom {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @include mq(991px) {
                        width: 100%;
                        height: 50px;
                        margin-top: 10px;
                    }

                    label {
                        margin: 0;
                    }
                }

                .col-md-4 {

                    @include mq(500px) {

                        .label-check {
                            font-size: 16px;
                        }

                    }
                }

                .col-md-3 {

                    @include mq(500px) {
                        width: 100%;
                    }

                    @include mq(991px) {

                        .form-inline {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;

                            label {
                                margin: 0;
                            }
                        }
                    }
                }
            }

            .label-check {
                font-size: 20px;
                font-weight: bold;
                padding-left: 45px;
                font-size: 20px;
                color: black;
                display: flex;
                align-items: center;

                &::before {
                    top: auto;
                }
            }
            input {
                border: 1px solid #ccc;
                border-radius: 4px;
                padding: 0px;
                width: 45px;
                padding: 0 5px;
                height: 30px;
                margin-right: 20px;
                text-align: center;

                &:focus {
                    border: 1px solid #ccc;
                }
            }
            .therd-row-label-sec {

                label {
                    color: #001f5d;
                    font-size: 20px;
                    font-weight: bold;
                }
                span {
                    color: #888888;
                    font-size: 15px;
                }
            }
            .therd-row-custom {
                padding: 30px 0;
                background: #1567b1;
                width: 200px;

                label {
                    color: white;
                    font-size: 30px;
                    font-weight: bold;
                    margin-top: 20px;
                }
            }
        }

    }

    /////////////////////////////////////////////
    /////////////////////////////////////////////

    .four-row {
        h2 {
            font-size: 30px;
            font-weight: bold;
            color: black;
            text-align: left;
            padding-left: 10px;
        }
    }

    /////////////////////////////////////////////
    /////////////////////////////////////////////

    .five-row {

        @include mq(1200px) {
            flex-direction: column;

            .col-md-6 {
                width: 100%;
            }
        }
        .well {
            background: white;
            padding: 30px;
            border: 0px;
            text-align: center;
            position: relative;

            @include mq(1200px) {
                padding: 10px;
            }

            .five-row-left {
                margin-bottom: 20px;

                @include mq(450px) {
                    flex-direction: column;
                    width: 100%;

                }

                &:last-of-type {
                    margin-bottom: 0;
                }

                @include mq(1200px) {
                    display: flex;
                    justify-content: space-between;

                    .text-left {
                        width: 50%;
                        display: flex;
                        align-items: center;

                        @include mq(450px) {
                            width: 100%;
                        }

                        .label-check {
                            font-size: 16px;

                            @include mq(450px) {
                                width: 100%;
                            }
                        }
                    }

                    .col-md-5 {
                        width: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;

                        @include mq(450px) {
                            width: 100%;
                            justify-content: flex-start;
                        }

                    }
                }
                .label-check {
                    font-size: 20px;
                    font-weight: bold;
                    color: black;
                }
                label {
                    color: #001f5d;
                    font-size: 20px;
                    font-weight: bold;
                }
                span {
                    color: #888888;
                    font-size: 15px;
                    margin-right: 5px;
                    margin-left: 5px;
                }
                input {
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    padding: 0 5px;
                    width: 45px;
                    height: 25px;
                    text-align: center;
                }

            }

            .five-row-right {
                text-align: left;
                margin-bottom: 20px;

                &:last-of-type {
                    margin-bottom: 0;
                }
                .label-check {
                    font-size: 20px;
                    font-weight: bold;
                    color: black;
                }
                label {
                    color: #001f5d;
                    font-size: 20px;
                    font-weight: bold;
                    margin-bottom: 0px;
                }
                span {
                    color: #888888;
                    font-size: 15px;
                    margin-right: 5px;
                    margin-left: 5px;
                }
                input {
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    height: 25px;
                    padding: 0 5px;
                    width: 45px;
                    text-align: center;
                    margin-top: 5px;
                }

                .form-inline {

                    label {

                        @include mq(400px) {
                            font-size: 16px;
                        }
                    }
                }
            }

            .five-row-right2 {
                text-align: left;
                margin-bottom: 20px;
                margin: 0;
                label {
                    color: #001f5d;
                    font-size: 20px;
                    font-weight: bold;
                    margin-bottom: 0px;
                    margin-right: 40px;
                }
                .form-inline {
                    margin-bottom: 15px;

                    @include mq(450px) {
                        flex-direction: column;
                        flex-flow: wrap;

                        label {
                            width: 100%;
                        }
                    }
                }
                a {
                    font-size: 15px;
                    color: #888888;
                    margin-left: 20px;
                    margin-bottom: 10px;
                }
            }

        }
    }

    /////////////////////////////////////////////
    /////////////////////////////////////////////

    .six-row {
        .row {
            margin-bottom: 20px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }

        .col-md-12 {
            @include mq(600px) {
                padding: 0;
            }
        }

        .well {
            background: white;
            padding: 30px;
            border: 0;
            position: relative;

            @include mq(600px) {
                padding: 15px;
            }

            .six-row-float {
                display: flex;
                justify-content: flex-start;
                span {
                    color: #888888;
                    font-size: 15px;
                    text-align: left;

                    @include mq(600px) {
                        font-size: 14px;
                    }
                }

                label {
                    color: black;
                    font-size: 20px;
                    font-weight: bold;
                    margin-bottom: 0;
                    display: flex;
                    align-items: center;
                    text-align: center;

                    @include mq(600px) {
                        font-size: 14px;

                        &::before {
                            left: 7px;
                        }
                    }

                    &::before {
                        top: auto;
                    }
                }

                input {
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    padding: 0 5px;
                    width: 45px;
                    height: 25px;
                    text-align: center;
                }
                &:nth-of-type(1) {
                    width: 30%;
                }
                &:nth-of-type(2) {
                    width: 20%;
                }
                &:nth-of-type(3) {
                    width: 10%;
                }
                &:nth-of-type(4) {
                    width: 20%;
                }
                &:nth-of-type(5) {
                    width: 15%;

                    span {
                        padding: 0px;
                    }
                    label {

                    }
                }
            }

        }
    }

    /////////////////////////////////////////////
    /////////////////////////////////////////////

    .seven-row {

        .panel-heading {
            border-radius: 0px;
            background: #001f5d;
            color: white;
            padding: 20px;

            h4 {
                position: relative;
                font-size: 20px;
                font-weight: bold;
                color: white;
            }

            i {
                position: absolute;
                right: 10px;
                top: 5px;
            }

        }

        .seven-row-p {

        }

        .seven-row-download {
            color: #001f5d;
            font-size: 15px;
        }

    }

    /////////////////////////////////////////////
    /////////////////////////////////////////////

    .eight-row {

        table {
            thead {
                background: #b6bed1;
                font-size: 20px;
                font-weight: bold;
                color: white;

                tr th {
                    text-align: center;
                    border: 0px;
                }
            }

            tbody {

                tr td {
                    text-align: center;
                    border: 0px;
                    color: #001f5d;
                    font-size: 15px;
                }

            }
        }

    }

    /////////////////////////////////////////////
    /////////////////////////////////////////////

    .nine-row {
        margin-bottom: 40px;
        table {
            thead {
                background: #b6bed1;
                font-size: 20px;
                font-weight: bold;
                color: white;

                th {
                    text-align: center;
                    border: 0px;

                    &:nth-of-type(1) {
                        width: 70%;
                    }
                    &:nth-of-type(2) {
                        width: 30%;
                    }
                }
            }

            tbody {

                tr td {
                    text-align: center;
                    border: 0px;
                    font-weight: bold;
                    color: black;
                    font-size: 18px;
                }

            }
        }
        input {
            background: none !important;
        }
    }

    /////////////////////////////////////////////
    /////////////////////////////////////////////

    .teen-row {

        label {
            padding: 15px 20px 10px 20px;
            color: #333333;
            font-size: 15px;
        }

        input {
            height: 60px;
            width: 100%;
            -webkit-border-radius: 28;
            -moz-border-radius: 28;
            border-radius: 28px;
            padding: 10px 20px 10px 20px;
            border: 1px solid #b6bed1;
        }

        button {
            height: 60px;
            background: #15BEC5;
            color: $white;
            width: 80%;
            text-align: center;

            -webkit-border-radius: 28;
            -moz-border-radius: 28;
            border-radius: 28px;
            padding: 10px 20px 10px 20px;
            text-decoration: none;

            &:hover {
                text-decoration: none;
            }
        }

        .text-right {
            display: flex;
            flex-direction: column;

            button:last-of-type {

                @include mq(991px) {
                    margin: 30px auto 0;
                }

            }
        }
    }

}





















