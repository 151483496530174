.transfers-filters {
    background-color: $blue-dark;
    padding: 20px;
    border-radius: 3px;
}
.transfers-filters__title {
    color: $white;
    font-size: 30px;
    font-weight: 700;
    line-height: 1;
    margin: 0 0 40px;
}
.transfers-filters__form {
    label {
        color: $white;
    }
    .slider-wrapper {
        margin: 15px 0;
    }
    .slider-range {
        background-color: $white;
        width: 100%;
        margin: auto;
        height: 3px;
    }
    .input-range .ui-slider-horizontal .ui-slider-handle {
        background-color: #15bec5;
        outline: none;
        height: 10px;
        border-radius: 10px;
        width: 30px;
        margin-left: -15px;
    }
    .input-checkbox {
        margin-top: 10px;
    }
    input {
        width: 100%;
    }
    @include mq(1080px) {
        .slider-wrapper {
            margin: 0 0 15px;
        }
    }
}
.transfers-filters__title-sm {
    display: block;
    font-size: 20px;
    color: $white;
    margin: 15px 0;
}
.transfers-filters__title--blue {
    background-color: $blue;
    padding: 10px 20px;
    font-size: 16px;
    @include mq(767px) {
        display: flex;
        .triangle {
            margin-left: 10px;
        }
    }
}
.triangle {
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7.5px 0 7.5px 13.0px;
    border-color: transparent transparent transparent $white;
    //transform: translate(50px, 2px);
    margin-top: 2px;
    float: right;
}
.transfers-filters__btn {
    background-color: $white;
    margin: 15px 0;
    padding: 10px 25px !important;
    border: 1px solid transparent !important;
    &:hover {

    }
}
.transfers-item {
    background-color: #f2f4f8;
    color: #888;
    margin-bottom: 20px;
}
.transfers-item__main-title-wrap {
    display: flex;
    justify-content: space-between;
}
.transfers-item__main-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.4;
    margin: 0;
    color: $blue-dark;
}
.transfers-item__main-reg-date {
    display: block;
    text-transform: uppercase;
    font-style: italic;
    margin-bottom: 15px;
    @include mq(767px) {
        text-align: right;
    }
}
.trans-rating {
    width: 109px;
    text-align: center;
    display: block;
    color: #fff;
    font-weight: 700;
    padding: 3px 0;
    background-color: #15bec5;
    border-radius: 3px;
    margin-left: auto;
}
.transfers-item__main-data {
    ul {
        list-style: none;
        margin: 0 0 15px;
        padding: 0;
    }
    li span {
        font-weight: 800;
    }
}
.transfers-item__price {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 15px;
    color: $white;
    background-color: $blue-dark;
    line-height: 1.2;
    min-height: 330px;
    height: 100%;
}
.transfers-item__links {
    display: flex;
    background-color: #e2e5ed;
    padding: 22px 20px;
    position: relative;
    a {
        color: $blue-dark;
        display: block;
        line-height: 1;
        float: left;
        margin-left: 20px;
        &:first-child {
            margin-left: 0;
        }
        &:hover {
            color: $white;
        }
    }
    @include mq(767px) {
        flex-wrap: wrap;
        justify-content: center;
        a {
            position: relative;
            top: initial;
            right: initial;
            width: initial;
            padding: 15px 5px;
            &:first-child {
                margin-left: 20px;
            }
        }
    }
}
.transfers-item__links-reservation {
    position: absolute;
    top: 0;
    right: 0;
    width: 13.8%;
    padding: 23px 0;
    background-color: #15bec5;
    color: $white;
    font-size: 13px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    @include mq(767px) {
        position: relative;
        top: initial;
        right: initial;
        width: initial;
        padding: 15px;
    }
}
.mobile-hide {
    @include mq(767px) {
        display: none;
    }
}
.transfers-item__main-data__payment {
    margin-bottom: 15px;
}