
.conteiner-notification{
    width: 250px; 
    position:fixed;
    bottom:0px;
    right:0px;
    z-index: 1; 
    padding-bottom:10px;

    .wrap-notification{
        width: 100%; 
        background-color: #2AC3C9; 
        color: white; 
        text-align: center; 
        border-radius: 10px;
        padding: 16px;
        margin-top:10px;
        
        .show-notification {
            visibility: visible;
            -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
            animation: fadein 0.5s, fadeout 0.5s 2.5s;
        }
        
        label{
            font-size:15px;
            font-weight:normal;
        }
        h3{
            font-size:21px;
            font-weight:bold;
            margin:0px;
            margin-top:5px;
        }
    }
    
    
    @-webkit-keyframes fadein {
        from {bottom: 0; opacity: 0;} 
        to {bottom: 30px; opacity: 1;}
    }
    
    @keyframes fadein {
        from {bottom: 0; opacity: 0;}
        to {bottom: 30px; opacity: 1;}
    }
    
    @-webkit-keyframes fadeout {
        from {bottom: 30px; opacity: 1;} 
        to {bottom: 0; opacity: 0;}
    }
    
    @keyframes fadeout {
        from {bottom: 30px; opacity: 1;}
        to {bottom: 0; opacity: 0;}
    }
}  

.beforeAlertCheck::before{
    border:1px solid red !important;
}

