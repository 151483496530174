.well-blue-light {
    background-color: $blue-dim;
    min-height: 540px;
    margin-bottom: 0;
    border-radius: 0;

    input,
    textarea {
        border-radius: 4px;
        border: none;
        padding: 5px;
    }

    @include mq(1024px) {
        min-height: 1050px;
    }
}

.profile-data-desc {
    height: 150px;
    resize: none;
}

.profile-data-input-label {
    padding: 0;
    padding-top: 10px;
    color: #001F5C;
    font-weight: 700;
}

.form-container {
    padding: 0;
}

.btn-settings {
    display: flex;
    padding: 10px 25px;
    margin: 15px 0;
    position: absolute;
    cursor: pointer;
    background: #15bec5;
    border-radius: 30px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;

    @include mq(1024px) {
        margin: 135px 0 15px;
    }
}

.btn-close {
    color: $blue-lighter;
}

.subuser-permission {
    font-size: 12px;
    font-weight: 700;
    color: $blue-dark;
    padding-left: 15px;
}

.subuser-permissions-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 50%;

    ul {
        width: 33%;
    }

    @include mq(992px) {
        max-width: 100%;
    }
}

.subuser-permission-row {
    display: flex;

    li {
        margin-right: 5px;
    }
}

.subuser-permission-form {
    width: 50%;
    margin-bottom: 15px;

    @include mq(600px) {
        width: 100%;
    }
}

.subuser-permission-form-select {
    text-align: left;
    text-align-last: left;
    margin-bottom: 15px;
}

.subuser-permission-form-label {
    color: $blue-dark;
}

.btn-subuser {
    text-transform: uppercase;

    &:hover {
        background-color: $white;
        color: $sky-blue !important;
    }
}

.panel-default {
    .panel-heading-settings {
        background-color: $blue-dark;
        min-height: 60px;

    }

    .panel-title {
        line-height: 60px;

        a:active {
            text-transform: none;
        }
    }

    .panel-heading-settings:hover {
        .panel-title {
            color: $sky-blue;
        }
    }
}

.settings-input {
    border-radius: 4px;
    border: none;
    padding: 5px;
}

.panel-body {
    background-color: $white;
    min-height: 200px;

    input:not(.hidden-input) {
        display: block;

        &[type="checkbox"] {
            display: none;
        }
    }

    input[type="submit"] {
        background: $sky-blue !important;
        text-transform: uppercase;
        margin-top: 15px;
        border-radius: 25px !important;
        height: initial !important;
        border: 1px solid $sky-blue !important;

        &:hover {
            background-color: transparent !important;
            color: $sky-blue !important;
        }
    }

    @include mq(1400px) {
        input:not(.hidden-input) {
            display: block !important;
            float: none !important;
        }
    }
}

.settings-rest {
    padding: 0 !important;
}

.clicked {
    border: 2px solid $blue;
    border-radius: 0 !important;
}

.mt-20 {
    margin-top: 20px;
}

.height-init {
    height: initial !important;
}

.panel-group-limited {
    max-width: 640px;
}

.f-panel-body-limited {
    padding: 30px;
    display: flex;
    min-height: 1px;
}

.settings-form {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.f-label {
    width: 70%;
    padding-right: 30px;
    color: #333333;
    font-weight: bold;
    font-size: 18px;

    &::before {
        left: 5px;
    }
}

.f-btn {
    margin-left: auto;
    margin-right: 0;
    margin-top: 15px;
}

.f-panel-title,
.f-panel-title a {
    margin: 0;
    font-size: 30px;
    color: $white;
}

.f-panel-heading.f-panel-heading {
    background-color: #1468b1;
    padding: 20px 55px;
}

.f-panel.f-panel.f-panel {
    margin-bottom: 30px;
}

.f-input {
    align-self: center;
    width: 30%;
    height: 40px;
    padding: 5px;
    border: none;
}

.f-sub-group {
    display: flex;
}

.has-sub-group:checked ~ .sub-group {
    display: none;
}

.sub-group {
    display: flex;
}

.f-label-fo {
    padding-left: 40px;
}

.f-label-full {
    width: 100%;
    margin-bottom: 30px;
}

.sub-hidden {
    display: none;
}

.f-panel-group-full {
    width: 100%;
}

.subuser-permission-form-full {
    width: 100%;
}

.f-label-small {
    margin-bottom: 10px;
    margin-top: 0;
    width: 100%;
    color: #333333;
    font-size: 20px;
    font-weight: bold;
}

.f-select {
    min-height: 200px;
}

.f-all-perms {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
}

.f-perm-item.f-perm-item.f-perm-item {
    display: flex;
    background-color: #1468b1;
    color: $white;
    padding: 0;
    margin-right: 15px;
    margin-bottom: 15px;
    height: 36px;
    overflow: hidden;
    border-radius: 2px;
}

.f-perm-close {
    display: block;
    width: 36px;
    height: 100%;
    background-color: #e22626;
    color: $white;
    text-align: center;
    line-height: 36px;
    font-weight: bold;
    font-size: 1em;

    &:hover {
        background-color: lighten(#e22626, 10);
        color: $white;
    }
}

.f-perm-item-txt {
    text-transform: capitalize;
    color: $white;
    line-height: 36px;
    padding: 0 10px;
}

.subuser-name {
    font-size: 23px;
    color: $blue-dark;
    font-weight: bold;
    cursor: pointer;

    &:hover {
        color: $blue-lighter;
    }
}

.alert.message {
    @include mq(767px) {
        font-size: 14px;
        width: 100%;
    }
}
