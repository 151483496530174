.saglasan {
   padding: 20px;
}
.ugovor {
	padding: 0 20px;
	a {
		display: inline-block;
		margin-left: 5px;
		color: $submit-form;
		transition: $dur $ease;
		&:hover {
			color: $blue-dark;
		}
	}
}
.uploadImage {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	background-color: $blue-dark;
	width: 300px;
	margin-bottom: 15px;
	border: 1px solid transparent;
	transition: $dur $ease;
	label {
		margin-bottom: 0;
		padding: 15px;
		color: $white;
		transition: $dur $ease;
		cursor: pointer;
	}
	input {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		cursor: pointer;
	}
	&:hover {
		cursor: pointer;
		background-color: transparent;
		border: 1px solid $blue-dark;
		label {
			color: $blue-dark;
		}
	}
}
.form-wrapper {
	max-width: 430px;
	padding: 135px 0 260px;

	.form-head {
		background-color: #001f5d;
		text-align: center;
		border-radius: 6px 6px 0 0;

		.section-title {
			color: #fff;
			font-size: 20px;
			line-height: 50px;
		}
	}

	.form-body {
		background-color: #f2f4f8;
		padding: 30px 35px 50px;
		border-radius: 0 0 3px 3px;

		h3 {
			padding-left: 15px;
			font-weight: bold;
			color: $blue-dark;
			font-size: 20px;
		}

		.form-row {
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0;

			a {
				font-size: 12px;
				color: #001f5d;
			}
		}

		.input-field {
			display: flex;
			align-items: baseline;
			justify-content: space-between;
			width: 100%;
			padding: 5px 20px;

			&.half {
				width: 50%;
			}
			@include mq(767px) {
				flex-wrap: wrap;
			}
		}
	

		label {
			flex: 0 0 150px;
			font-weight: normal;
			font-size: 15px;
			color: $blue-dark;
		}

		input {
			max-width: initial;
			flex: 1 1 100%;
			padding: 8px 15px;
			font-weight: normal;
			font-size: 15px;
			color: $blue-dark;
			border: 1px solid transparent;
			transition: $dur $ease;
			&:focus {
				border: 1px solid $blue;
			}
		}
		select {
      max-width: initial;
      flex: 1 1 100%;
      padding: 8px 15px;
      font-weight: normal;
      font-size: 15px;
      color: $blue-dark;
    }

		input[type="submit"] {
			margin: 25px auto;
			background-color: #1368b1;
			color:white;
			padding: 15px 60px;
		}
		
		input[type="button"]{
		  margin: 25px auto 0;
      background-color: #1368b1;
      color:white;
      padding: 15px 60px;
		}

		a.center {
			color: #001f5d;
			text-align: center;
			margin: 20px auto 0;
			display: block;
		}
	}
}

.ui-tabs {
	padding: 0;

	.ui-tabs-nav {
		padding: 0;
		border: 0;

		li {
			margin: 0;
			background: #f2f4f8;
			a {
				text-transform: uppercase;
				padding: 0;
				line-height: 40px;
				background: $blue-faded;
				color: $white;
				border-radius: 0 0 6px 6px;
			}

			&:first-child a {
				border-radius: 0 0 6px 0;
			}

			&:last-child a {
				border-radius: 0 0 0 6px;
			}

			&.ui-tabs-active a {
				background: #f2f4f8;
				color: $blue-dark;
			}
		}
	}
}

.column-wrapper {
	.column-2 {
		width: 50%;
		float: left;
	}
}
.alert-text {
	color: red;
	display: block;
	bottom: -20px;
	left: 50%;
    text-align: center;
}
