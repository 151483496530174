/*--------------------------------------------------------------
## queryForCompany-form
--------------------------------------------------------------*/

.bus-with-driver {
  label {
  }

  input {

    &::before {
      position: relative;
    }

    &::after {
      display: none;
    }
  }

  .driver-titles {

  }

  .driver-left {

    .input-checkbox {

      label {
        

      }
    }

    input {
      .fieldAlert {
        border: 1px solid red;
      }
    }

    .div-inputs {
      

      &.single {
        span {
          width: 74%;

          input {
            width: 100%;
          }


        }
      }

      span {

        span {
          font-size: 12px;
        }
      }
    }
  }
}
.evo-ne-znam {
  input {
    padding-left: 10px; 
  }
  i {
    right: 10px !important;
  }
}
.accomodation-row {
  label, select {
    display: block;
    margin: 10px 0;
    min-width: 200px;
  }
}