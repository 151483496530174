.testemonials-wrapper {
    margin-bottom: 15px;
    &::after {
        content: '';
        display: block;
        margin-top: 15px;
        border-bottom: 2px solid #001f5d;
    }
    &:last-child {
        &::after {
            display: none;
        }
    }
}
.testimonial-header {
    margin-bottom: 10px;
}
.testimonial-header__item {

}
.testimonial-stars {

}
.testimonial-text {

}
.testemonials-text__positive {
    color: green;
}
.testemonials-text__negative {
    color: $red;
}
.testimonials-admin {

}