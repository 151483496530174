//main
$white: #fff;
$black: #333;
// Body
$body-bg: #f5f8fa;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097d1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;
$wish-price: #47C6F1;
$blue-lighter: #1f86c1;
$blue-dark: #001f5d;
$blue-darker: #071530;
$blue: #1368b1;
$blue-light: #dee6f7;
$blue-faded: #848ead;
$blue-dim: #dfe6f8;
$background-color: #f2f4f8;
$scroll-wish: #0ae;
$copyright: #030916;
$footer: #081530;
$scroll-back: #e9f0ff;
$red: #da1717;
$sky-blue: #15bec5;
$sky-blue-light: #16bec5;
$grey: #cdd1dc;
$grey-lighter: #e7eaf2;
$grey-light: #ebebeb;
$grey-dark: #333;
$grey-shade: #7a7a7a;
// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #636b6f;
$yellow: #ffc731;
$background-body: #F3F4F8;
.section-title {
	font-size: 30px;
}

.section-title2 {
	font-size: 20px;
	font-weight: bold;
	margin: 0;
	padding: 0;
}

p {
	word-break: break-word;
}

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;
$checkbox-radio: #1468b1;
$submit-form: #15bec5;

.submit-form {
	background: $submit-form;
	color: $white;
	cursor: pointer;
	border: 1px solid $submit-form;
	transition: all 350ms ease;

	&:hover {
		background: white;
		border: 1px solid $submit-form;
		color: $submit-form;
	}
}

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

$blue-footer: #679aca;
// Transitions
$dur: .3s;
$ease: ease;

// Breakpoints variables
$bs-tablet: 1199px;