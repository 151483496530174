.wrap {
    margin-top: -30px;
    padding: 0px !important;

    .well {
        border: 0px;
    }

    .sideBar {
        padding: 0px;

        .well {
            background: #011F5D;
            border: 0px;
            color: white;
            padding: 30px;
        }

        h3 {
            font-weight: bold;
            font-size: 30px;
            margin: 10px 0px 10px 0px;
        }

        h4 {
            font-size: 18px;
            margin-top: 24px;
        }

        .firstInp {
            margin-bottom: 10px;
        }

        .sideBar-input {
            background: white;
        }

        .label-check {
            font-size: 15px;
            margin-top: 2px;
            &::before {
                left: 3px;
            }
        }

        .btn {
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 100%;
            height: 50px;
            color: $white;
            background: #15BEC5;
            border-radius: 0px;
        }

        .check-side-bar {
            margin-bottom: 30px;
        }

    }

    .content-mid {
        padding-right: 0px;
        @include mq(767px) {
            padding-right: 15px;
        }
        .content-mid-first-well {
            background: #1468B1;
            color: white;
        }

        select {
            color: #001f5d;
            font-size: 15px;
            font-weight: bold;
        }

        .content-mid-context {
            background: white;
            position: relative;
            border: 'none';
            height: 100%;

            .content-mid-context-post {
                background: #F3F4F8;
                padding-bottom: 20px;

                .large-img {
                    width: 100%;
                    height: 180px;
                    background: #CDD1DC;
                    margin-top: 20px;
                }

                .small-img {
                    margin-top: 20px;
                    float: left;
                    width: 30%;
                    height: 50px;
                    background: #CDD1DC;

                }

                .small-img:nth-child(3) {
                    margin-right: 5%;
                }

                .small-img:nth-child(2) {
                    margin-right: 5%;
                }

                .clear {
                    clear: both;
                }

                h6 {
                    font-size: 13px;

                    span {
                        font-weight: bold;
                    }

                    i {
                        font-size: 15px;
                        margin-right: 2px;
                    }
                }

                h1 {
                    font-size: 30px;
                    font-weight: bold;
                    color: #001f5d;
                }

                .data-size {
                    font-size: 14px;
                    color: #888888;
                }


                .label-price {
                    padding-top: 20px;

                    .data-size {
                        margin-top: 17px;
                        font-size: 14px;
                        color: #888888;
                    }

                    .price-agenci {
                        margin-top: 5px;
                        font-size: 15px;
                        color: white;
                        font-weight: bold;
                        background: #15BEC5;
                        border-radius: 5px;
                        padding: 10px;
                    }
                }

                .right-side-post {
                    background: #011F5D;
                    height: 88.5%;
                    width: 9.6%;
                    position: absolute;
                    right: 0px;
                    top: 0px;
                    margin-top: 20px;
                    border: 0px;

                    div {
                        position: absolute;
                        top: 35%;
                        left: 0%;
                        width: 100%;
                        text-align: center;
                        color: white;
                        text-align: center;

                        span {
                            font-size: 16px;
                        }

                        label {
                            font-weight: bold;
                            font-size: 30px;
                        }
                    }

                    button {
                        position: absolute;
                        bottom: 0px;
                        left: 0px;
                        width: 100%;
                        border: 0px;
                        height: 50px;
                        background: #15BEC5;
                        border-radius: 0px;
                    }
                    @include mq(767px) {
                        height: auto;
                        position: static;
                        width: 100%;
                        margin-bottom: 30px;
                        div {
                            position: static;
                            top: initial;
                            left: initial;
                        }
                    }
                }
                @include mq(767px) {
                    padding: 0;
                }
            }
        }
    }


}

.aranzmanFile {
    display: inline-block;
    position: relative;
    font-size: 22px;
    font-weight: bold;
    margin: 15px 0;
}

.table-wrapp {
    display: flex;
    flex-direction: column;
    @include mq(767px) {
        overflow-x: auto;
    }
}

.order-list {
    overflow-x: scroll;
}
.table-mobile {
    @include mq(767px) {
        overflow-x: scroll;
        
    }
    &::-webkit-scrollbar {
        height:7px;
      }
      
    /* Track */
    &::-webkit-scrollbar-track {
    height:5px;
    background: silver; 
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
    background: #1368B1; 
    }
    
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
    background: #1368B1; 
    }
}
.table-mobile td {
    padding: 8px 16px !important;
    min-width: 130px;
}
.table-actions {
    display: flex;
    justify-content: center;

    a {
        margin: 0 5px;
    }
}
.travel-price {
    display: flex;
    align-items: flex-start;
    input:checked ~ label::before {
        background-color: $blue;
    }
    .label-check {
        padding-top: 40px;

    }
}
.beds-room {
    color: $blue;
    margin-left: 15px;
}
.beds-room__number {
    color: $blue;
    font-size: 52px;
    margin: 0 25px 0 15px;
}
.beds-room__text {
    display: inline-block;
    width: 150px;
    font-size: 20px;
    color: #333;
    text-align: left;
    font-weight: bold;
}
.tooltip-icon {
    display: block;
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: -5px;
    @include mq(767px) {
        margin-left: 15px;
    }
}
.well--wide {
    padding: 35px 19px 25px;
    label {
        margin-top: 6px;
    }
}

.side-bar-range {
    margin-top: 20px;

    h4 {
        margin: 10px 0px 10px 0px;
        padding-left: 0px;
    }

    .ui-slider {
        background: white;
        height: 5px;
        border: 0px;
        position: relative;
        width: 100%;
        margin-top: 10px;

        .ui-slider-range {
            background: #16BDC5;
        }

        span {
            background: #16BDC5;
            height: 12px;
        }
    }

    .in-line-div {
        float: left;
        text-align: center;
    }

    .in-line-div:nth-child(2) {
        width: 20%;
    }

    .in-line-div:nth-child(3) {
        width: 60%;
    }

    .in-line-div:nth-child(4) {
        width: 20%;
    }
}

.side-bar-range:nth-child(7) {
    margin-bottom: 40px;
}

#vehicle-filter-grade {
    .in-line-div:nth-child(3) {
        width: 54%;
    }
    .in-line-div:nth-child(4) {
        width: 26%;
    }
}

.comfirm-pre-delete {
    position: absolute;
    left: 0;
    z-index: 1;
}

.pre-delete-tour {
    position: relative;
    z-index: 2;
}