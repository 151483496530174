.main-content-profile {
    
    .loader {
      display:none;
      height: 4px;
      width: 100%;
      position: relative;
      overflow: hidden;
      background-color: #ddd;
    }
    .loader:before{
      display: block;
      position: absolute;
      content: "";
      left: -200px;
      width: 200px;
      height: 4px;
      background-color: #2980b9;
      animation: loading 2s linear infinite;
    }
    
    @keyframes loading {
        from {left: -200px; width: 30%;}
        50% {width: 30%;}
        70% {width: 70%;}
        80% { left: 50%;}
        95% {left: 120%;}
        to {left: 100%;}
    }
    .wrap-black{
        width:100%;
        position:fixed;
        top:0;
        left:0;
        height:100%;
        background-color: rgba(0, 0, 0, 0.59);
        z-index:9999999999999999999999999;
        display:none;
    }
    .lds-hourglass {
      display:none;
      display: inline-block;
      position: fixed;
      width: 64px;
      height: 64px;
      top:15px;
      left:200px;
      z-index:999999999999999999999;
    }
    .lds-hourglass:after {
      content: " ";
      display: block;
      border-radius: 50%;
      width: 0;
      height: 0;
      margin: 6px;
      box-sizing: border-box;
      border: 26px solid #fff;
      border-color: #fff transparent #fff transparent;
      animation: lds-hourglass 1.2s infinite;
    }
    @keyframes lds-hourglass {
      0% {
        transform: rotate(0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
      50% {
        transform: rotate(900deg);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      }
      100% {
        transform: rotate(1800deg);
      }
    }
    
    
    .modal_my{
        display:none;
        overflow-y:scroll;
        width:100%;
        height:100%;
        position:fixed;
        background-color: rgba(0, 0, 0, 0.59);
        
        top:0px;
        left:0px;
        z-index:9999999;
        
        .modal_my_form{
            position:relative;
            width: 99%;
            margin:auto;
            margin-top:50px;
            min-height:100%;
            background:#DFE6F8;
            .modalHeader{
                height:70px;
                width:100%;
                background:#1468B1;
                
                label{
                    padding:20px;
                    color:white;
                    font-weight:normal;
                    font-size:18px;
                    
                    span{
                        font-weight:bold;
                    }
                }
            }
            
            .closeX{
               position:absolute;
               top:10px;
               right:25px;
               z-index:999;
               font-weight:bold;
               color:white;
               cursor:pointer;
               font-size:20px;
            }
            
            .left_modal{
                padding:0px;
                height: 80vh;
                overflow-y:scroll;
                border-right:1px solid white;

                @media screen and (max-width: 1023px) {
                    height: 35vh;
                }
                
                #dayLeftModal{
                    padding:0px;
                    li{
                        position:relative;
                        min-height: 25px;
                        background:#82D56D;
                        cursor:pointer;
                        span{
                            position:absolute;
                            left:15px;
                            top:-10px;
                            color:black;
                        }
                        .bigHour{
                            color:#1567B0;
                            font-size:18px;
                            font-weight:bold;
                            top:-15px;
                        }
                    }
                }
            }
            .center_modal{
                border-right:1px solid white;
                border-left:1px solid white;
                
                .closed{
                    background:#FF0000;
                }
                
                .reservation{
                    background:#FFEC6C;
                }
                
                .From-To{
                    background:#A2A2A2;
                }
                
                .private{
                    background:#4FC2F8;
                }
                
                .unconfirmed{
                    background:#FEC107;
                }
                
                span{
                    font-size:16px;
                    font-weight:bold;
                    color:#38487B;
                    padding-top:5px;
                    padding-bottom:5px;
                    width:100%;
                }
                ul{
                   padding:0px;
                   margin-top:20px;
                   margin-bottom:20px;
                   li{
                      padding: 7px 15px;
                      cursor:pointer;
                   }
                }
                
                .date-modal-center{
                    margin-top:20px;
                    margin-bottom:20px;
                    width:100%;
                    
                    label{
                       display: inline-flex;
                       color:#38487B;
                       
                       &::before{
                          margin-left:20px;
                       }
                    }
                }
                
                .ride-modal-center{
                    margin-top:20px;
                    margin-bottom:20px;
                    label{
                        color:#3A3769;
                    }
                }
                
                .time-modal-center{
                    margin-top:20px;
                    margin-bottom:20px;
                    .input-group{
                        width:100%;
                        .span1{
                            float:left;
                            width:15%;
                            button{
                                width:100%;
                            }
                        }
                        .span2{
                            float:left;
                            width:70%;
                            input{
                                width:100%;
                            }
                        }
                        .span3{
                            float:left;
                            width:15%;
                            button{
                                width:100%;
                            }
                        }
                    }
                }
            }
            .right_modal {
                position:relative;
                border-left:1px solid $white;
                background:#DFE6F8;
                .right_modal_info {
                    padding-top:20px;
                    display:none;
                    p {
                       span {
                           &:nth-child(1n) {
                              font-size: 16px;
                              font-weight: bold;
                              color: #38487B;
                              padding-top: 5px;
                              padding-bottom: 5px;
                           }
                           &:nth-child(2n) {
                              font-size: 16px;
                              color: #38487B;
                              font-weight: normal;
                              padding-top: 5px;
                              padding-bottom: 5px;
                           }
                       }
                    }
                }
                
                .right_modal_form {
                    display:none;
                    .col-md-12 {
                       margin-bottom:15px; 
                    }
                    .col-md-6 {
                       margin-bottom:15px; 
                    }
                    .pad {
                       .col-sm-5{
                           padding-right:0px;
                       }
                    }
                    .col-md-8 {
                       margin-bottom:15px; 
                    }
                    span {
                        font-size: 16px;
                        font-weight: bold;
                        color: #38487B;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        width: 100%;
                    }
                    label {
                        color:#213769;
                    }
                }
                
                .right_modal_footer {
                    width:100%;
                    text-align:right;
                    
                    button{
                       margin-right:5px;
                       margin-bottom: 15px;
                       color:white;
                       background:#15BEC5;
                       padding:4px 25px;
                       border-radius:20px;
                       font-size:18px;
                    }
                }
            }
        }
    }
    
    .av-container {
        margin-bottom:50px;
        .inputs{
            margin-bottom:30px;
            position: relative;
            z-index: 0;
            
            .date-clear{
                .col-md-2{
                   padding:0;
                   
                   .btn{
                      width:100%;
                   } 
                }
                .col-md-8{
                   padding:0; 
                }
            } 
        }
        
        .header{
            width:100%;
            display: flex;
            justify-content: space-between;
            background:#1368b1;
            padding:15px;
            h3{
                color:white;
                text-align:left;
                margin:0px;
                font-size:20px;
            }
            .from_to{
                text-align:right;
            }
        }
        .scrolls{
            overflow:scroll;
            min-height:175px;
            
            .under_header{
                display:flex;
                height:60px;
                
                .buses-wrap-header{
                    min-width: 212.48px;
                    height:60px;
                    border-bottom:1px solid white;
                    border-right:1px solid white;
                    background:#dee6f7;
    
                    .header_div{
                        width:100%;
                        margin: 0px;
                        padding-left:20px;
                        
                        label{
                            font-size:18px;
                            color:#001f5d !important;
                        }
                    }
                }
                .days-wrap-header{
                    height:60px;
                    white-space: nowrap;
                    
                    .widthChange1{
                        width: 1487.36px !important;
                        
                        .days-doted-text{
                            span{
                                margin-right: 24.2% !important;
                            }
                        }
                    }
                    
                    .widthChange2{
                        width: 743.68px !important;
                        
                        .days-doted-text{
                            span{
                                margin-right: 22.9% !important;
                            }
                        }
                    }
                    
                    .widthChange3{
                        width: 495.78px !important;
                        
                        .days-doted-text{
                            span{
                                margin-right: 21.9% !important;
                            }
                        }
                    }
                    
                    .widthChange4{
                        width: 371.84px !important;
                        
                        .days-doted-text{
                            span{
                                margin-right: 20.2% !important;
                            }
                        }
                    }
                    
                    .widthChange5{
                        width: 297.47px !important;
                        
                        .days-doted-text{
                            span{
                                margin-right: 19.2% !important;
                            }
                        }
                    }
                    
                    .widthChange6{
                        width: 247.89px !important;
                        
                        .days-doted-text{
                            span{
                                margin-right: 18% !important;
                            }
                        }
                    }
                    
                    .days-mini-header{
                        height:60px;
                        position:relative;
                        width: 212.48px;
                        display:inline-block;
                        
                        background:#dee6f7;
                        border-right:1px solid white;
                        
                        label{
                            width:100%;
                            padding:5px;
                            font-size:12px;
                            color:#001f5d;
                            text-align:center;
                        }
                        .days-doted-text{
                            position:absolute;
                            width:100%;
                            bottom:0;
                            left:-10px;
                            
                            span{
                                margin-right:19.2%;
                                color:#001f5d;
                                font-weight:bold;
                                font-size:12px;
                            }
                        }
                        .days-doted{
                            position:absolute;
                            width:1%;
                            left:25%;
                            border-right:3px dotted white;
                            z-index:5;
                        }
                        .days-doted1{
                            position:absolute;
                            width:1%;
                            left:50%;
                            border-right:3px dotted white;
                            z-index:5;
                        }
                        .days-doted2{
                            position:absolute;
                            width:1%;
                            left:75%;
                            border-right:3px dotted white;
                            z-index:5;
                        }
                    }
                }
            }
            
            .table-wrap{
                display:flex;
                flex-wrap:nowrap;
                
                .vertical-wrap{
                    min-width:36px;
                    writing-mode: vertical-rl;
                    text-orientation: mixed;
                    padding:5px;
                    background:#DEE6F7;
                    border-bottom:1px solid white;
                    border-left:0;
                    font-size: 18px;
                    color: #001f5d !important;
                    font-weight:bold;
                }
                
                .div-right-wrap{
                    min-width:36.48px;
                    background:#DEE6F7;
                    border-left:1px solid white;
                    border-bottom:1px solid white;
                }
                
                .buses-wrap{
                    min-width:140px;
                    background:#DEE6F7;
                    border:1px solid white;
                    .buses{
                        min-height:34px;
                        border:0;
                        border-bottom:1px solid white;
                        text-align:center;
                        color:#001f5d;
                        font-size:14px;
                        padding:5px;
                        margin:0px;
                    }
                }
                
                .days-wrap{
                    background:silver;
                    
                    .days-mini-wrap{
                        display: flex;
                        
                        .days{
                            width:212.48px;
                            min-height:34px;
                            border-bottom:1px solid white;
                            border-right:1px solid white;
                            background:#81d56d !important;
                            position:relative;
                            &.sunday, &.saturday {
                                background-color: #b39ddb !important;
                                
                            }
                        }
                        
                        .widthChangeDays1{
                            width: 1487.36px !important;
                        }
                        
                        .widthChangeDays2{
                            width: 743.68px !important;
                        }
                        
                        .widthChangeDays3{
                            width: 495.78px !important;
                        }
                        
                        .widthChangeDays4{
                            width: 371.84px !important;
                        }
                        
                        .widthChangeDays5{
                            width:297.47px !important;
                        }
                        
                        .widthChangeDays6{
                          width: 247.89px !important;
                        }
                    }
                }
            }
        }
    }
}


.av-container-margin-top {
    margin-top: 50px;
}
.col-form-label-icon {
    margin-left: -15px;
    margin-top: 5px;
}
.calendar-time-input {
    .input-group-btn {
        position: static;
        top: initial;
        left: initial;
        right: initial;
        bottom: initial;
        width: initial !important;
        padding-top: 0 !important;
    }
    .glyphicon {
        padding: 0 !important;
    }
    .glyphicon::before {
        font-size: 12px;
    }
}