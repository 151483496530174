/*--------------------------------------------------------------
## reservation-info.blade
--------------------------------------------------------------*/
.reservation-pass-edit {

  form {

    .half-container {

      .form-row {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @include mq(500px) {
          flex-direction: column;
        }

        .input-field {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
  

          @include mq(500px) {
            margin-bottom: 10px;
          }

          .inner {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            @include mq(500px) {
              flex-direction: column;
            }

            input {
              margin-left: 0;
              margin-bottom: 10px;

              &:last-of-type {
                margin-left: 5px;

                @include mq(500px) {
                  margin-left: 0;
                }
              }
            }
          }

          label {
            text-align: left;
            margin: 0;
            width: 100%;
          }

          input {
            margin: 0;
            width: 98%;
          }

          &.single {

            input {
              width: 100%;
            }
          }
        }
      }
    }

    .half-right {
      padding-right: 15px;

      .travelers {

        .input-field {
          display: flex;
          flex-flow: wrap;
          justify-content: space-between;
      
          @include mq(500px) {
            flex-direction: column;
          }

          label {
            display: flex;
            width: 100%;
            justify-content: flex-start;
          }

          input {
            width: 32%;

            @include mq(500px) {
              width: 100%;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}