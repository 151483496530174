/*--------------------------------------------------------------
## Home-bus
--------------------------------------------------------------*/
select {
    text-align: center;
    text-align-last: center;
    cursor: pointer;
}

.banner {
    @include mq(1300px) {
        height: 16vw;
    }
}

.home-banner {
    background-attachment: fixed;
    background-size: 100% 100%;

    .banner-caption {
        padding: 0;
        display: flex;
        flex-direction: column;

        .register-home-button {
            color: $blue-dark;
            border: 2px solid $blue-dark;
            border-radius: 30px;
            padding: 10px 25px;
            font-size: 25px;
            font-weight: 100;
            max-width: 550px;
            margin-top: 35px;
            text-align: center;
            box-shadow: 0 0 0 rgba(0, 31, 93, 0.84);
            animation: pulse 2s infinite;
        }
    }
}

.banner-sm {
    height: 300px !important;

    .banner-caption {
        top: 45%;
    }
}

.banner-caption {

    p,
    span {
        font-size: 18px;
    }
}

.banner-caption__title {
    font-size: 40px;

    @include mq(767px) {
        font-size: 32px;
    }
}

.banner-caption-title-wrap {
    display: flex;
    padding-top: 60px;

    h1,
    .icon-info2 {
        display: inline-block;
        align-self: center;
    }

    .icon-info2 {
        padding-top: 5px;
    }
}

.transfer-icon-info-container {
    position: fixed;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: flex-end;
    top: 125px;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 999999;

    .transfer-icon-info-wrapper {
        max-width: 1200px;
        margin: auto;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        position: relative;

        .transfer-icon-single-info {
            display: none;
            padding: 20px;
            background: $submit-form;
            width: 54%;
            position: absolute;

            p {
                font-size: 16px;
                color: $white;
            }
        }
    }
}

// .transfer-row-th {
// 	.container {
// 		.trh-route {
// 			width: 9%;
// 			text-align: center;
// 		}
// 	}
// }

.title-rute-mobil {
    display: none;
}

.transfer-info {
    display: none;
    padding: 20px;
    background: $white;
    color: $black;
    position: relative;
    margin: auto;
    width: 99%;
    font-size: 18px;
    font-weight: bold;
    border-left: 1px solid $blue-dark;
    border-right: 1px solid $blue-dark;
}

.site-main {
    .home-container {
        margin-top: -200px;
        padding: 0;
    }

    .form-row {
        margin-bottom: 10px;
    }

    .transfer-left {
        label {
            margin-left: 13px;

            &:before {
                margin-top: 4px;
            }

            @include mq(767px) {
                margin-left: 0;
            }
        }
    }

    .transfer-right {
        select {
            width: 70%;
        }
    }

    .home-search-container {
        padding: 20px;

        .section-title {
            margin-bottom: 15px;
            padding-top: 4px;
            margin-left: 5px;
        }
    }

    .long-input-field {
        width: 100%;

        span {
            // width:40px;
            display: block;
            width: 100%;
            margin: 0;
        }
    }
}

.form-row-edit-sas {

    .transfer-left {
        width: 50%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .transfer-right {
        width: 50%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-right: 0;

        .icon-info-parent {
            position: relative;
            width: 45px;
            border: 1px solid $white;
            border-radius: 30px;
            height: 26px;
            display: flex;
            align-items: center;

            .icon-info2 {
                position: absolute;
                left: -1px;
            }
        }
    }
}

.home-left-edit {
    .home-you-need {
        .form-row-edit-sas {
            background: $blue;
        }

        .form-row-edit-sas-even {
            background: $blue-dark;
        }
    }

    .buses-checkbox {
        &::before {
            left: 0;
            top: 0;
        }
    }
}

#non-switch-div {
    padding: 0 30px;
    border-radius: 10px;
    margin-top: 25px;

    .non-switch-div-wrap {
        border: 2px solid white;
        border-radius: 10px;
        padding: 30px 30px 45px;

        .section-title2 {
            margin: 0;
        }

        &.non-switch-div-wrap-home-initial {
            padding: 45px 30px;

            p {
                margin-bottom: 0;
            }
        }
    }
}

#non-aero-choose {
    display: none;
    padding: 19px 30px;
    border-radius: 10px;
    margin-top: 25px;

    .non-choose-wrap {
        border: 2px solid white;
        border-radius: 10px;
        padding: 30px 30px 45px;

        .section-title2 {
            margin: 0;
        }
    }
}

.home-left {
    .checkbox-display {
        margin-top: 25px;

        .switch-div-sas {
            background: #001f5d;
            display: flex;
            justify-content: start;
            margin-bottom: 22px;

            input {
                max-width: 100%;
            }
        }

        .time-input {

            span {
                height: 33px;
                width: 30px;
                margin-top: -1px;
            }

            input {
                border-radius: 0;
            }
        }
    }
}

#cont {
    span {
        height: 33px;
        width: 30px;

        button {
            margin-right: 1px !important;
        }
    }

    input {
        border-radius: 0;
    }
}

.home-search {
    flex-direction: column;

    .cssSlider {
        margin-top: 40px;
    }

    .home-search-container {
        form {
            padding-bottom: 75px;
            position: relative;

            .submit-form {
                position: absolute;
                bottom: -20px;
                right: 0;
            }
        }

        .form-col {
            .input-field {
                display: flex;

                input,
                select {
                    width: 100%;
                    height: 33px;
                }

                label {
                    align-self: center;
                }
            }

            &:nth-child(odd) {
                width: 45%;
                margin-right: 5%;
            }
        }
    }

    .slick-track {
        a {
            position: relative;

            img {
                height: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

#transfer-scroll {
    max-height: 720px;
    overflow: auto;

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: $scroll-back;
    }

    &::-webkit-scrollbar {
        width: 12px;
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: $blue;
    }

    .transfer-row {
        margin-right: 20px;

        &:nth-of-type(1) {
            h3 {
                .icon-info2 {
                    .tool-text {
                        transform: translate(15%, -50%);
                        top: 50%;
                        left: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &::before {
                            display: none;
                        }

                        &::after {
                            content: '';
                            display: block;
                            width: 0;
                            height: 0;
                            border-top: 9px solid transparent;
                            border-bottom: 9px solid transparent;
                            border-right: 9px solid $blue-dark;
                            position: absolute;
                            left: -1%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }
                }
            }
        }

        .transfer-inner-row {
            transition: all 150ms linear;
            display: flex;
            justify-content: space-between;

            &:hover {
                background: rgba(21, 190, 197, 0.5);
            }

            span {
                text-transform: capitalize;
            }

            .trd-route {
                width: 25%;
            }

            .trd-date {
                width: 10%;
            }

            .trd-time {
                width: 8%;
            }

            .trd-people {
                width: 8%;
            }

            .trd-remaining {
                width: 49%;
                padding-right: 15px;

                a {
                    border: 1px solid $blue-dark;

                    &:hover {
                        border: 1px solid $blue-dark;
                        background: $white;
                        color: $blue-dark
                    }
                }
            }

            @include mq(1400px) {
                span p {
                    text-align: right;
                }
            }

            @include mq(1200px) {
                .country-id {
                    left: 0;
                    top: 10px;
                }
            }
        }
    }
}

.transfer-market {
    .transfer-market-form {
        padding: 30px 0;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-attachment: fixed;

        .container {
            text-align: center;

            p {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 0;
            }

            h2 {
                font-size: 36px;
            }

            .transfer-market-icons {
                display: flex;
                justify-content: space-around;
                width: 70%;
                margin: auto;

                p {
                    padding: 25px 0;
                    font-size: 14px;
                    font-weight: 500;
                }

                .icons-market-wrap {
                    img.viewportElement {
                        animation: scaleIconTrans 1s;
                        animation-delay: 0.3s;
                    }
                }
            }
        }
    }
}

.tours-see-container {
    padding: 55px 0 80px;

    @include mq(779px) {
        padding: 55px 15px 80px;
    }
}

.search-bus-tour-container {
    position: relative;
    background: $blue-lighter;

    form {
        padding: 40px 0 70px;
    }

    .search-bus-tour-wrap {
        display: flex;
        justify-content: space-between;
        flex-flow: wrap;

        div {
            display: flex;
            flex-direction: column;
            justify-content: center;

            p {
                margin-bottom: 10px;
                color: $white;
            }
        }

        #form-slider-range {
            #slider-range {
                top: 5px;
                border: none;
                background: $white;
                border-radius: 10px;

                .ui-slider-range {
                    background: $white;
                }

                .ui-state-default {
                    width: 10px;
                    border-radius: 0;
                    top: -3px;
                }
            }
        }

        input {
            padding: 8px;
        }

        select {
            padding: 8px;
        }
    }

    .container {
        position: relative;

        .section-title {
            color: $white;
            margin-bottom: 20px;
        }

        .submit-form {
            position: absolute;
            display: flex;
            text-align: center;
            right: 0;
            bottom: -25px;
            height: 50px;
            transform: translateX(0);
        }
    }
}

.bus-tours-container-new {
    .bus-tours {
        .bus-tours-wrap {
            display: flex;
            justify-content: space-between;
            flex-flow: wrap;

            .carousel-inner .item div {
                @include mq(480px) {
                    display: block !important;
                }
            }

            .carousel-inner .item .home-bus-single {
                @include mq(480px) {
                    margin: 0 0 20px !important;
                }
            }

            .home-bus-single {
                transition: all 450ms ease;
                width: 48%;
                display: flex;

                .home-bus-single-img {
                    transition: all 450ms ease;
                    width: 266px;

                    a {
                        transition: all 450ms ease;

                        img {
                            transition: all 450ms ease;
                        }
                    }
                }

                .home-bus-info {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    padding: 0 35px;
                    width: 225px;

                    h3 {
                        font-size: 20px;
                        max-width: 100%;
                    }
                }

                .home-bus-price {
                    width: 95px;
                    background: $blue-dark;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @include mq(480px) {
                        text-align: center;
                    }

                    span {
                        color: $white;
                        font-size: 30px;
                        font-weight: bold;
                    }
                }

                a {
                    overflow: hidden;
                    position: relative;

                    .a-info-text {
                        transition: all 450ms ease;
                        position: absolute;
                        width: 100%;
                        height: 110%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        top: -10%;

                        span {
                            opacity: 0;
                            height: 100%;
                        }
                    }
                }
            }

            .hover {
                box-shadow: 0 0 20px rgba(31, 134, 193, 0.55);

                a {
                    overflow: hidden;

                    .a-info-text {
                        position: absolute;
                        background: rgba(21, 190, 197, 0.5);
                        transition: all 450ms ease;
                        z-index: 99;

                        span {
                            animation: hoverText 0.2s linear forwards;
                            opacity: 1;
                            color: white;
                            font-weight: bold;

                            &:nth-child(1) {
                                animation-delay: 0.1s;
                            }

                            &:nth-child(2) {
                                animation-delay: 0.2s;
                            }

                            &:nth-child(3) {
                                animation-delay: 0.3s;
                            }

                            &:nth-child(4) {
                                animation-delay: 0.4s;
                            }
                        }
                    }
                }

                .home-bus-single-img {
                    a {
                        img {
                            animation: scale 10s linear forwards;
                        }
                    }
                }

                .home-bus-info {
                    h3 {
                        a {
                            color: $blue;
                        }
                    }
                }
            }
        }
    }
}

#cont {
    position: relative;
}

.input {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    // border: 1px solid rgba(0, 0, 0, .15);

    &:last-child {
        margin-bottom: 0;
    }

    input {
        position: relative;
        display: inline-block;
        margin: 0;
        box-sizing: border-box;
        border: none;
        text-align: center;
        padding: 9px 15px;

        &:focus {
            outline: 0;
        }
    }

    span {
        position: absolute;
        width: 35px;
        height: 35px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
        cursor: pointer;
        z-index: 9;

        &:nth-of-type(1) {
            left: -5px;
        }

        &:nth-of-type(2) {
            right: 0;
        }
    }
}

.mobiltip {
    display: none;
    padding: 15px;
    background: $blue;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: 0;

    p {
        margin: 0;
        color: $white;
        font-weight: bold;
    }
}

/*********************responsive********************/
@media only screen and (max-width: 1600px) {
    .site-main {
        .home-container {
            margin-top: -9%;
        }
    }
}

@media only screen and (max-width: 1199px) {
    .title-rute-mobil {
        display: block;
    }

    #transfer-scroll {
        // .transfer-row {
        // 	position: relative;
        // 	display: flex;
        // 	flex-flow: wrap;

        // 	h3 {
        // 		position: absolute;
        // 		top: -35px;
        // 	}

        // 	.transfer-inner-row {
        // 		width: 48%;
        // 		margin: 5px;
        // 		flex-direction: column;

        // 		.trd-route {
        // 			width: 60%;
        // 			padding: 0;

        // 			p {
        // 				width: auto;
        // 			}
        // 		}

        // 		.trd-date {
        // 			width: 60%;
        // 			padding: 0;

        // 			p {
        // 				width: auto;
        // 			}
        // 		}

        // 		.trd-time {
        // 			width: 60%;
        // 			padding: 0;

        // 			p {
        // 				width: auto;
        // 			}
        // 		}

        // 		.trd-people {
        // 			width: 60%;
        // 			padding: 0;

        // 			p {
        // 				width: auto;
        // 			}

        // 			span {
        // 				left: auto;
        // 				top: 48%;
        // 				font-size: 20px;
        // 				margin-right: 20px;
        // 			}
        // 		}

        // 		.trd-remaining {
        // 			width: 60%;
        // 			padding: 0;
        // 			display: block;

        // 			p {
        // 				width: auto;
        // 				font-size: 16px;
        // 			}

        // 			a {
        // 				border: 1px solid $blue-dark;
        // 				width: auto;
        // 				margin: 10px 0;

        // 				&:hover {
        // 					border: 1px solid $blue-dark;
        // 					background: $white;
        // 					color: $blue-dark
        // 				}
        // 			}

        // 			.trem-info {
        // 				display: flex;
        // 				justify-content: center;
        // 			}
        // 		}

        // 		span {
        // 			display: flex;
        // 			justify-content: space-between;

        // 			label {
        // 				margin: 0;
        // 				font-size: 16px;
        // 				text-align: left;
        // 			}
        // 		}
        // 	}
        // }
    }
}

@media only screen and (max-width: 1199px) {
    .banner.home-banner {
        margin-top: 100px;
        height: 50vh;

        .banner-caption {
            top: 40%;

            h1 {
                font-size: 60px;
            }

            .register-home-button {
                padding: 5px 10px;
            }
        }
    }

    .transfer-market {
        .tours-see-container {
            padding: 30px 0;
            width: 100%;
        }
    }
}

@media only screen and (max-width: 991px) {
    .mobiltip {
        opacity: 1;
    }

    .transfer-market-form {
        margin-top: 30px;
    }

    .site-main {
        .home-container {
            margin-top: -250px;

            .home-right {
                .cssSlider {
                    margin-top: 55px;
                }
            }
        }
    }

    .search-bus-tour-container {
        .container {
            .submit-form {
                bottom: -55%;
            }
        }
    }

    .bus-tours-container-new {
        .bus-tours {
            .bus-tours-wrap {
                .home-bus-single {
                    width: 100%;

                    .home-bus-single-img {
                        width: 40%;
                    }

                    .home-bus-info {
                        width: 45%;
                    }

                    .home-bus-price {
                        width: 15%;
                    }
                }
            }
        }
    }

    #transfer-scroll {
        .transfer-row {
            .transfer-inner-row {
                padding-top: 50px;

                .trd-route {
                    width: 100%;
                }

                .trd-date {
                    width: 100%;
                }

                .trd-time {
                    width: 100%;
                }

                .trd-people {
                    width: 100%;
                }

                .trd-remaining {
                    width: 100%;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .banner.home-banner {
        .banner-caption {
            h1 {
                font-size: 45px;
            }

            .register-home-button {
                display: block;
                width: 90%;
                padding: 5px;
                font-size: 18px;
            }
        }
    }

    .site-main {
        padding: 0 15px;
    }

    .transfer-market {
        .transfer-market-form {
            .container {
                .transfer-market-icons {
                    width: 100%;
                }
            }
        }
    }

    .transfer-icon-info-container {
        .transfer-icon-info-wrapper {
            .transfer-icon-single-info {
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 660px) {
    #transfer-scroll {
        .transfer-row {
            .transfer-inner-row {
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 576px) {
    .site-main {
        .home-container {
            margin-top: -54px;
        }
    }

    .transfer-market {
        .transfer-market-form {
            .container {
                .transfer-market-icons {
                    flex-direction: column;

                    .icons-market-wrap {
                        margin-bottom: 15px;
                    }
                }

                p {
                    font-size: 14px;
                }

                h2 {
                    font-size: 20px;
                }
            }
        }
    }

    .form-row-edit-sas {
        .transfer-right {
            .icon-info2 {
                margin-left: 10px;
            }
        }
    }

    .home-left {
        .checkbox-display {
            .time-input {
                padding: 0;
            }

            .switch-div-sas {
                input {
                    width: 100%;
                }
            }

            .form-col {
                padding: 0;
            }
        }
    }

    .home-search {
        .home-search-container {
            .form-col:nth-child(odd) {
                width: 100%;
            }

            .input-field {
                width: 100%;
                justify-content: space-between;
            }
        }
    }

    .search-bus-tour-container {
        .search-bus-tour-wrap {
            div {
                width: 100%;
                margin-bottom: 10px;
            }
        }
    }

    .search-bus-tour-container {
        .container {
            .submit-form {
                bottom: -23%;
            }
        }

        @include mq(767px) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .bus-tours-container-new {
        .bus-tours {
            .bus-tours-wrap {
                .home-bus-single {
                    width: 100%;
                    flex-direction: column;

                    .home-bus-single-img {
                        width: 100%;
                    }

                    .home-bus-info {
                        width: 100%;
                        padding: 15px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        h3 {
                            font-size: 18px;
                        }
                    }

                    .home-bus-price {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .search-bus-tour-container .container .submit-form {
        bottom: 15px;
    }

    .bus-tours-container-new {
        padding: 0;
    }
}

.section-subtitle {
    font-weight: bold;
    color: #323232;
}

.box-content {
    padding: 20px 30px;

    .row {
        margin-bottom: 20px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .row-oprema-item {
        font-weight: bold;
        font-size: 20px;
        max-width: 200px;
    }
}

.box-content-row {
    display: flex;
    flex-wrap: wrap;
}

.box-content-item {
    display: flex;
    align-items: flex-start;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;

    &:last-child {
        margin-right: 0;
    }
}

.box-content-label {
    display: inline;
    margin-right: 15px;
    font-size: 20px;
    color: $grey-dark;
}

.box--white {
    background-color: $white;
}

.box--grey {
    background-color: $grey;
}

.box-label {
    font-size: 20px;
    color: $grey-dark;
    min-width: 180px;
}

.box-text {
    font-size: 20px;
    font-weight: bold;
    color: $blue-dark;
    text-align: right;

    .glyphicon {
        margin-right: 0;

        &::before {
            color: $white;
            font-size: 18px;
        }

        &:first-child {
            margin-left: 5px;
        }
    }
}

.box-content-text {
    font-size: 20px;
    font-weight: bold;
    color: $blue-dark;

    .glyphicon {
        margin-right: 0;

        &::before {
            color: $white;
            font-size: 18px;
        }

        &:first-child {
            margin-left: 5px;
        }
    }
}

.box-content-question,
.box-content-answer {
    font-size: 22px;
    color: $grey-dark;
    font-weight: bold;
}

.box-content-question {
    margin-right: 15px;
}

.box-content-answer {
    color: $blue-dark;
    margin-left: auto;
    text-align: center;
    align-self: center;
}

.divider--blue {
    background-color: $blue;
    height: 60px;
}

.box-about {
    margin: 0 -15px;
    padding: 40px;
    background-color: $white;
}

.box-about-title {
    margin: 0;
    font-size: 20px;
    color: black;
    font-weight: bold;
}

.box-about-text {
    font-size: 20px;
    color: black;
    line-height: 1.8;
    margin-bottom: 30px;
}

.img-full {
    min-height: 355px;
    margin-bottom: 30px;
}

.content-placeholder {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    img {
        width: 45%;
    }

    .content {
        width: 50%;
    }
}

.box-about-list {
    list-style: none;
    color: black;
    font-size: 20px;
    padding: 0;
    margin: 0 0 30px 0;

    li {
        margin-bottom: 10px;

        &::before {
            content: '- ';
            display: inline-block;
            margin-right: 4px;
        }
    }
}

.blockquote {
    text-align: center;
    color: black;
    font-size: 20px;

    blockquote {
        margin-top: 60px;
        font-size: 20px;
        font-style: italic;
        border: 0;
    }
}

.blockquote-author {
    font-weight: bold;
}

.table {

    .starColorAdd,
    .glyphicon-star-empty {
        font-size: 16px;
    }

    .stars-wrap .glyphicon:hover {
        cursor: default;
    }
}

.table-transfer {

    td,
    th,
    tr {
        padding: 0;
        border: none !important;
        text-align: center;
    }

    thead {
        background-color: $blue;
        color: $white;
    }
}

.transfer-market-text {
    display: flex;

    .tooltipGlo {
        align-self: center;
    }

    @include mq(767px) {
        justify-content: center;
    }
}

.driver-terms-confirm {
    .input-checkbox {
        display: flex;
        margin-top: 20px;
    }

    label:before {
        margin-left: 16%;
        margin-right: 8%;
    }

    a {
        display: inline-block;
        margin: 0 5px;
        white-space: nowrap;
    }

    @include mq(991px) {
        .driver-suit {
            display: flex;
            justify-content: center;

            &::before {
                margin: 0 20px;
            }
        }
    }
}

.driver-text-label {
    @include mq(991px) {
        text-align: center;
    }
}

.driver-terms-confirm--center {
    label:before {
        margin: 0 10px 0 0;
    }

    .buses-checkbox {
        width: 100%;
        justify-content: center;
    }
}

.banner-caption-flex {
    display: flex;
}
