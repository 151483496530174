/*--------------------------------------------------------------
## Icons
--------------------------------------------------------------*/
.icon-info2 {
  display: inline-flex;
  font-size: x-large;
  cursor: pointer;
  position: relative;
  margin-left: 5px;
  //animation: pulse 2s infinite;
  //border-radius: 50%;

  .tool-text {
    position: absolute;
    opacity: 0;
    font-family: "Roboto , snas-serif";
    font-size: 16px;
    background: $blue-dark;
    padding: 15px;
    border-radius: 10px;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: all 500ms ease;
    color: $white;
    bottom: 10px;
    width: 300px;
    text-align: center;
  }
  &::before {
    align-self: center;
  }
  
}
/******************responsvie**********************/
@media only screen and (max-width: 991px) {
  .icon-info2 {
    .tool-text {
      width: auto;
    }
  }
}