.modal_book_offer {
    .modal-content {
        padding: 20px;

        h2 {
            font-size: 30px;
            line-height: 25px;
            color: black;
            font-weight: bold;
            margin-top: 30px;
            margin-bottom: 30px;
        }

        label {
            color: black;
            font-size: 20px;
            line-height: 25px;
            margin-bottom: 20px;
            margin-top: 20px;
        }

        span {
            color: #02205E;
            font-weight: bold;
            font-size: 16px;
        }

        p {
            color: #7B7B7B;
            font-weight: bold;
            font-size: 16px;
        }

        a {
            color: #1567B0;
            font-weight: bold;
            text-decoration: underline;
        }
    }
}


.singleImg {
    label {
        margin-top: 10px;
        font-size: 14px;
    }
}

.send-offer {
    position: relative;

    .bt-bew {
        position: fixed;
        width: 100%;
        bottom: 0px;
        left: 0px;
        height: 40px;
        z-index: 999;
        font-size: 20px;
        font-weight: bold;
        border-radius: 0px;
    }

    textarea {
        resize: none;
        overflow: auto;
        padding: 15px;
        line-height: 1.3;
        letter-spacing: 0.3px;
    }

    @include mq(760px) {
        padding-left: 40px;
        padding-right: 40px;
    }

    .alert {
        ul {
            list-style-type: none;
        }
    }

    .click {
        background: #CCD1DC;
    }

    h2 {
        font-size: 30px;
        line-height: 25px;
        color: black;
        font-weight: bold;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .left {
        float: left;
        width: 30%;
        background: #F2F4F8;
        height: 420px;
        margin-right: 2%;
        overflow-y: scroll;

        @include mq(991px) {
            width: 100%;
            margin-bottom: 20px;
        }

        &::-webkit-scrollbar {
            width: 1.5em;
        }

        &::-webkit-scrollbar-track {
            background-color: #ccd1dc;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #1368b1;
            border-radius: 30px;
        }

        .blue {
            width: 100%;
            padding: 15px;
            background: #1368b1;
            margin-bottom: 20px;

            label {
                color: white;
                font-size: 20px;
                line-height: 25px;
            }
        }

        .name {
            width: 100%;
            margin-bottom: 2px;
            cursor: pointer;

            .image {
                float: left;
                width: 40%;
                margin-right: 2%;
                padding: 5px 20px 5px 30px;

            }

            .image-text {
                float: right;
                width: 58%;

                p {
                    margin-top: 15px;
                    font-size: 15px;
                    line-height: 30px;
                    font-weight: bold;
                }
            }
        }
    }

    .right {
        float: right;
        width: 68%;
        background: #F2F4F8;
        height: 420px;

        @include mq(991px) {
            width: 100%;
        }

        @include mq(700px) {
            height: auto;
        }

        .blue {
            width: 100%;
            padding: 15px;
            background: #1368b1;

            label {
                color: white;
                font-size: 20px;
                line-height: 25px;
            }
        }

        .name {
            width: 100%;

            .image {
                float: left;
                width: 68%;
                margin-right: 2%;

                @include mq(700px) {
                    width: 100%;
                    float: none;
                }

                img {
                    width: 100%;
                    height: 360px;
                    object-fit: cover;
                }
            }

            .image-text {
                float: right;
                width: 30%;
                padding-top: 20px;
                padding-left: 40px;

                @include mq(991px) {
                    padding-left: 5px;
                }

                @include mq(700px) {
                    width: 100%;
                    float: none;
                    padding: 20px;
                }

                label {
                    margin-top: 20px;
                    font-size: 15px;
                    line-height: 30px;
                    font-weight: bold;
                    margin: 0px;
                }

                p {
                    margin-bottom: 10px;

                    @include mq(700px) {
                        width: auto;
                    }
                }

                input {

                    // @include mq(1200px) {
                    //     max-width:140px !important;
                    // }
                    @include mq(991px) {
                        margin-left: 0px !important;

                    }

                    @include mq(520px) {
                        width: 100% !important;
                    }
                }
            }
        }
    }

    .alertValSpan {
        display: block;
    }

    .clear {
        clear: both;
    }

    .singleImg {
        margin-bottom: 15px;
    }

    .sec {
        margin-top: 20px;

        .well {
            border: none;
            box-shadow: none;
            background: #ccd1dc;
            padding: 50px;

            label {
                margin-top: 10px;
                color: black;
            }

            .btn-bs-file {
                margin: 0px;
                padding: 9px 50px 9px 50px !important;

                @include mq(991px) {
                    margin-top: 20px;
                }
            }

            span {
                color: #565656;
            }
        }
    }

    .th-sec {
        margin-top: 20px;

        .well {
            border: none;
            box-shadow: none;
            background: #CCD1DC;

            .col-md-5 {
                p {
                    padding-left: 50px;

                    @include mq(760px) {
                        padding-left: 0px;
                    }
                }
            }

            .col-md-1 {
                padding: 0px;
            }

        }
    }

    .alone {
        textarea {
            width: 100%;
            height: 200px;
            resize: none;
        }
    }

    .th {
        margin-top: 20px;

        .well {
            border: none;
            box-shadow: none;
            background: #E7EAF3;
            padding: 50px;

            p {
                font-size: 20px;
                line-height: 35px;
                font-weight: bold;
                color: black;
                margin: 0px;
                padding-left: 30px;
            }

            .col-md-3 {
                i {
                    position: absolute;
                    right: 20px;
                    color: white;
                    z-index: 1;
                    top: 15px;

                    @include mq(1200px) {
                        right: 37px;
                    }

                }
            }

            .col-md-2 {
                span {
                    font-size: 15px;
                    font-weight: normal;
                    color: black;
                    margin-top: 10px;
                }
            }

            @include mq(992px) {
                .flex {
                    margin-bottom: 10px;
                }
            }

            @include mq(640px) {
                padding: 30px 15px;
            }

            @include mq(480px) {
                .oprema-input {
                    width: 70%;
                }
            }
        }
    }

    .cena-row {
        .well {
            border: none;
            box-shadow: none;
            background: #CDD1DC;
            padding: 40px;

            h2 {
                margin: 0px;

                @include mq(990px) {
                    text-align: left;
                    padding-bottom: 10px;
                }
            }
        }

    }

    .ponuda {
        background: #15BEC5;
        padding: 30px;
        color: white;
        text-align: center;
        width: 100%;
        height: 114px;
        font-size: 25px;
        font-weight: bold;
    }

}
