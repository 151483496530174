.glyphicon-star-empty {
  font-size: 20px;
  color: #CDD1DC;
  font-size:23px;
  margin-right:5px;
}

.starColorAdd {
  margin-right:5px;
  color: #FDCA39;
  font-size: 23px;
}
.starValue{
  display:none;   
}