
.box-header-title {
    padding: 20px;
    margin: 0;
    color: $white;
    background-color: $blue;
    font-weight: bold;
    font-size: 17px;
    p {
        margin: 0;
    }
}
.box-header-title--bkg-transparent {
    background-color: transparent;
    color: $blue-dark;
}
.box-content-wrap {
    padding: 20px 35px;
    background-color: $white;
    p {
        white-space: pre-line;
    }
}
.empty-offer-wrap {
    margin-top: -35px;
    .row {
        margin-bottom: 25px;
    }
}
.driver-img {
    @include mq(992px) {
        display: block;
        margin: 0 auto;
    }
}
.driver-name {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding: 18.5px 0;
    background-color: $white;
    margin-bottom: 0;
}

.small-thumbs {
    margin-top: 15px;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    img {
        margin-bottom: 15px;
        margin-right: 5px;
        width: 100px;
        height: 90px;
        display: inline-block;
        cursor:pointer;
    }
    
    &::-webkit-scrollbar {
      height:7px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
      height:5px;
      background: silver; 
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #1368B1; 
    }
    
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #1368B1; 
    }
}
#bigImg{
    width:100%;
    height:300px;
}
.company-profile {
    .img-responsive {
        margin: 0 auto;
    }
}
.company-profile-info {
    padding: 30px;
    background-color: $white;
    margin-left: -15px;
    min-height: 415px;
    @include mq(992px) {
        margin: 0 15px;
    }
}

.section-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 15px;
}
.box-content-bold {
    font-weight: bold;
    font-size: 20px;
}
.box-content-link {
    display: block;
    font-size: 20px;
    color: $blue;
    margin-bottom: 1em;
    &:hover {
        color: $sky-blue;
    }
}
.box-content-info {
    background-color: $grey;
    margin: 0 0 25px;
}
.box-content-info--grey-lighter {
    background-color: $grey-lighter;
}
.box-content-info-wrap {
    padding: 40px 0 20px 40px;
}
.box-content-info-wrap-flex {
    display: flex;
    justify-content: space-between;
    @include mq(500px) {
        flex-wrap: wrap;
    }
}
.box-content-info-time {
    background-color: $grey-lighter;
    padding: 45px 65px;
}
.info-icon {
    margin-left: 5px;
    display: inline-block;
}
.datepicker-wrap {
    position: relative;
    input {
        text-align: center;
        color: $white;
    }
    .glyphicon {
        position: absolute;
        right: 30px;
        top: 10px;
        &::before {
            color: $white;
        }
    }
}
.datepicker--blue {
    background-color: $blue;
    &::placeholder {
        color: $white;
        text-align: center;
    }
}
.time-picker {
    text-align: center;
}
.btn-huge {
    display: block;
    height: 120px;
    width: 100%;
    line-height: 120px;
    color: $white !important;
    background-color: $sky-blue !important;
    margin: 20px 0;
    font-size: 30px;
    padding: 0;
    text-align: center;
    span {
        display: block;
        width: 100%;
        text-align: center;
    }
    @include mq(450px) {
        font-size: 22px;
    }
}
.btn-cyan {
    background-color: $sky-blue;
    color: $white;
    font-size: 13px;
    padding: 15px 40px;
    border-radius: 2em;
}
.box-row-input-half {
    width: 48%;
}
.box-row-input-wrap {
    display: flex;
    justify-content: space-between;
}
.agree-section {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 25px;
}
.agree-section--full {
    width: 100%;
    justify-content: space-between;
    a {
        padding: 45px 0;
        font-size: 30px;
        width: 49%;
        text-align: center;
    }
}

.agree-btn {
    color: $white;
    transition: $dur $ease;
    padding: 20px 30px;
    font-size: 20px;
    font-weight: bold;
    &:hover {
        color: #23527c;
    }
}
.agree-btn--red {
    background-color: $red;
    position: relative;
}
.agree-btn--blue {
    background-color: $sky-blue-light;
    position: relative;
    &::after {
        border-top: 20px solid #16bec5 !important;
    }
}
.mr-15 {
    margin-right: 15px;
}
.m-0 {
    margin: 0;
}
.input-center {
    text-align: center;
    width: initial;
    &::placeholder {
        text-align: center;
    }
}
.box-content-info-currency {
    align-self: center;
    font-weight: bold;
    font-size: 16px;
    margin-left: 5px;
}
.time-wrap {
    display: flex;
}
.time-picker-btn {
    width: initial;
}
.time-picker-input {
    text-align: center;
}
.bkg-none {
    background-color: transparent;
}
.upload-status {
    margin-top: 5px;
    text-align: center;
    display: block;
    font-size: 13px;
}
.label-input-checked {
    &:before {
        background: $checkbox-radio;
    }
}
.payment-option-checkbox-wrap {
    padding: 30px 0 0;
    background-color: $grey-lighter;
    margin-left: 15px;
    margin-right: -15px;
    .form-inline {
        margin-bottom: 15px;
    }
    @include mq(776px) {
        margin: 0;
    }
}
.payment-option-wrap {
    .pay-row-cards-wrap {
        margin: 15px 30px;
    }
    .checkbox-pay-div {
        padding: 15px;
        min-height: 238px;
    }
    .pay-cards-container {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .credit-card-alert {
        text-align: center;
        padding-bottom: 1px;
        color: red;
    }
    .pay-row-paypall {
        padding: 15px;
    }
    .pay-row-paypall input {
        height: initial;
    }
    .pay-row {
        & .pay-row-left, & .pay-row-right {
            display: block;
        }
        &:nth-child(1) .pay-row-left input, &:nth-child(1) .pay-row-right input {
            width: 100%;
        }
        &:nth-child(2) .pay-row-left {
            width: 40%;
        }
        &:nth-child(2) .pay-row-right {
            width: 55%;
        }
        &:nth-child(2) .pay-row-left input, &:nth-child(2) .pay-row-right input {
            width: 100%;
        }
    }
}
#checkbox-pay-paypal {
    .pay-row-wrap {
        padding-top: 60px;
    }
}
.show-div {
    display: block !important;
    margin-bottom: 15px;
    min-height: 238px;
}
.hidden-offer-div, .hidden-offer-div-doesnt-fit {
    display: none;
}
.js-btn-active {
    &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
        border-left: 20px solid $background-body;
        border-right: 20px solid $background-body;
        border-top: 20px solid $red;
    }
}

.row-flex {
    display: flex;
    label {
        display: block;
        width: 50%;
    }
    span {
        display: block;
        width: 50%;
    }
}

.well-big {
    height: 382px !important;
}

.btn-upload {
    background: #15BEC5;
    color: white !important;
    border-radius: 75px;
    padding: 13px 30px 13px 30px !important;
    font-weight: bold;
    text-transform: uppercase;
    &:hover {
        background-color: $blue !important;
    }
}
.driver-title {
    margin-bottom: 45px;
}
.mt-40 {
    margin-top: 40px;
}
.mb-n {
    margin-bottom: 0 !important;
}
.well-grey {
    background-color: #e6eaf3;
    border: none;
}
.form-submit {
    display: none;
}
.js-form-input-active {
    display: block;
}
.imageFile {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    margin: 20px 0;
    .icon-remove {
        right: -20px;
        top: -20px;
    }
}

.check-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    margin-left: 30px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
}

/* Hide the browser's default radio button */
.check-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: $white;
    transform: translateY(-50%);
}

/* On mouse-over, add a grey background color */
.check-container:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.check-container input:checked ~ .checkmark {
    background-color: $blue;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.check-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
// .check-container .checkmark:after {
//  	top: 9px;
// 	left: 9px;
// 	width: 8px;
// 	height: 8px;
// 	background: white;
// }

.oprema-wrap {
    label {
        display: block;
    }
}
.oprema-wrap-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        width: 48%;
        margin-bottom: 10px;
        &:nth-child(odd) {
            margin-right: 2%;
        }
    }
    @include mq(992px) {
        justify-content: space-between;
        li {
            width: 30%;
            margin: 10px 5px;
            &:nth-child(odd) {
                margin-right: 0;
            }
        }
    }
}
.clickable-li {
    .dropdown-triangle {
        right: 0;
    }
}