.calendar-legend {
    position: fixed;
    left: 9%;
    top: 100px;
    max-width: 960px;
    width: 100%;
    z-index: 1;
    @include mq(767px) {
        top: 70px;
        left: 15px;
    }
}
.calendar-legend__header {
    padding: 15px;
    background-color: #1368b1;
    color: #fff;
    width: 110px;
    transition: .3s ease-in;
    h3 {
        margin: 0;
        font-size: 20px;
    }
    &:hover {
        cursor: pointer;
    }
    
}
.calendar-legend--active {
    .calendar-legend__header {
        width: 100%;
    }
}
.calendar-legend__body-wrap {
    display: none;
}
.calendar-legend__body {
    display: flex;
    padding-top: 15px;
    background-color: #fff;
    @include mq(960px) {
        flex-wrap: wrap;
    }
}
.calendar-legend__item {
    margin: 0 15px 15px;
}
.calendar-legend__item-text {
    display: block;
    text-align: center;
    text-transform: uppercase;
    color: darken(#1368b1, 15%);
    font-size: 14px;
    &::before {
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        background-color: #15bec5;
        margin: 0 auto 10px;
    }
    @include mq(1300px) {
        font-size: 12px; 
    }
}
.green {
    &::before {
        background-color: #80d56d;
    }
}
.yellow-light {
    &::before {
        background-color: #ffec6c;
    }
}
.yellow-dark {
    &::before {
        background-color: #fec107;
    }
}
.red {
    &::before {
        background-color: #ee534f;
    }
}
.grey {
    &::before {
        background-color: #a2a2a2;
    }
}
.blue {
    &::before {
        background-color: #4fc2f8;
    }
}
.purple {
    &::before {
        background-color: #b39ddb;
    }
}