/*--------------------------------------------------------------
## Keyframes
--------------------------------------------------------------*/
@-webkit-keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(0, 31, 93, 0.84);
	}
	70% {
		-webkit-box-shadow: 0 0 0 10px rgba(0, 31, 93, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(0, 31, 93, 0);
	}
}

@keyframes pulse {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(0, 31, 93, 0.84);
		box-shadow: 0 0 0 0 rgba(0, 31, 93, 0.84);
	}
	70% {
		-moz-box-shadow: 0 0 0 10px rgba(0, 31, 93, 0);
		box-shadow: 0 0 0 10px rgba(0, 31, 93, 0);
	}
	100% {
		-moz-box-shadow: 0 0 0 0 rgba(0, 31, 93, 0);
		box-shadow: 0 0 0 0 rgba(0, 31, 93, 0);
	}
}

@keyframes scale {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.2);
	}
	100% {
		transform: scale(1.4);
	}
}

@keyframes scaleIconTrans {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.2);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes hoverText {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(50%);
	}
}
