

.schedule-container2 {
    margin-top: -45px;
    
    .first-row{
        margin-bottom: 20px;
        .col-md-3{
            padding-right: 0px;
        }
        .col-md-2{
            padding-left:0px;
            @include mq(991px) {
                padding-left: 15px;
            }
        }
        img{
            display: block;
            margin-left: auto;
            @include mq(991px) {
                height:auto;
                margin-top: 20px;
            }
        }
        .first-div{
            height:100%;
            @include mq(991px) {
                height: auto;
                padding-right: 15px;
            }
            .well{
                background: #F2F4F8;
                margin-bottom: 0px;
                height: 100%;
                border: none;
                box-shadow: none;
                padding: 20px;
                @include mq(991px) {
                    height: auto;
                }
                
                h2 {
                    font-size: 25px;
                    font-weight: bold;
                    margin-bottom: 15px;
                    
                }
                
                .pull-right{
                    width: 100%;
                    background: #15BEC5;
                    color: white;
                    padding: 5px 10px 5px 10px;
                    border-radius: 5px;
                    float: initial !important;
                }
                
                p{
                    font-size: 14px;
                    color: black;
                }
            }
        }
        .col-md-7{
            height: 100%;
            @include mq(991px) {
                height: auto;
            }
        }
        .sec-div{
            height: 100%;
            @include mq(991px) {
                height: auto;
            }
            .col-md-12{
                height: 120px;
                padding: 0px;
                @include mq(991px) {
                    padding: 0px 15px 0px 15px;
                }
                
                &:nth-child(1){
                    margin-bottom: 20px;
                }
                &:nth-child(2){
                    margin-bottom: 20px;
                }
            }
            
        }
        
        @include mq(991px) {
            height: auto;
        }
    }
    
    h2{
        font-size: 30px;
        line-height: 25px;
        font-weight: bold;
        color: black;
        padding: 20px;
        margin: 0px;
    }
    
    .sec-row{
        .well{
            background: #F2F4F8;
            border: none;
            box-shadow: none;
            position: relative;
            
            p{
                font-size: 16px;
                line-height: 30px;
                color: black;
            }
            
            #search-again{
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translate(-50%,-50%);
                background:#15bec5;
                border-radius: 20px;
                border: none;
                padding: 10px 15px 10px 15px;
                font-size: 13px;
                color: white;
                font-weight: bold;
                
                @include mq(991px) {
                    position:relative;
                    margin-left:100px;
                    margin-top:20px;
                }
            }
        }
    }
    
    .thr-row{
        .well{
            border:none;
            box-shadow:none;
            background:#ccd1dc;
            
            .col-md-7{
                h2{
                    font-size:20px;
                    line-height:33px;
                    color:#011f5d;
                    font-weight:bold;
                }
                .row{
                    margin-bottom:10px;
                    padding-left:20px;
                    padding-right:20px;
                }
                p{
                    margin-top:5px;
                }
            }
            .col-md-6{
                h2{
                    font-size:20px;
                    line-height:33px;
                    color:#011f5d;
                    font-weight:bold;
                }
                .row{
                    margin-bottom:10px;
                    padding-left:20px;
                    padding-right:20px;
                }
                p{
                    margin-top:5px;
                }
            }
            .col-md-5{
                h2{
                    font-size:20px;
                    line-height:33px;
                    color:#011f5d;
                    font-weight:bold;
                }
                .row{
                    margin-bottom:10px;
                    padding-left:20px;
                    padding-right:20px;
                }
                p{
                    margin-top:5px;
                }
            }
            .col-md-6{
                h2{
                    font-size:20px;
                    line-height:33px;
                    color:#011f5d;
                    font-weight:bold;
                }
                .row{
                    margin-bottom:10px;
                    padding-left:20px;
                    padding-right:20px;
                }
                p{
                    margin-top:5px;
                }
            }
        }
    }
    
    .info-row{
        .well{
            border:none;
            box-shadow:none;
            background:#ccd1dc;
            .row{
                margin-bottom:10px;
                padding-left:20px;
                padding-right:20px;
            }
            h2{
                font-size:20px;
                line-height:33px;
                color:#011f5d;
                font-weight:bold;
            }
            
            p{
                margin-top:5px;
                @include mq(991px) {
                    text-align:center;
                }
            }
            
            select{
                background:#1368b1;
                color:white !important;
            }
        }
    }
    
    .podaci-row{
        .well{
            border:none;
            box-shadow:none;
            background:#ccd1dc;
            
            
            .row{
                margin-bottom:10px;
                padding-left:20px;
                padding-right:20px;
            }
            h2{
                font-size:20px;
                line-height:33px;
                color:#011f5d;
                font-weight:bold;
            }
            
            p{
                margin-top:5px;
                @include mq(991px) {
                    text-align:center;
                }
            }
            
            select{
                background:#1368b1;
                color:white !important;
            }
        }
    }
    
    
    .zahtevi-row{
        .well{
            border:none;
            box-shadow:none;
            background:#ccd1dc;
            select{
                background:#1368b1;
                color:white !important;
            }
            p{
                font-size:16px;
                line-height:30px;
                color:#001f5d;
                margin:0px;
            }
            
            textarea{
                height:150px;
                resize: none;
                margin-bottom:20px;
            }
            .btn-bs-file{
                margin-left:60px;
                padding: 13px 80px 13px 80px !important;
            }
            .row{
                margin-bottom:10px;
            }
            span{
                font-size:16px;
                color:#565656;
                margin-left:20px;
            }
        }
    }
    
    .standard-row{
        .well{
            border:none;
            box-shadow:none;
            background:#F2F4F8;
            padding:0px 15px 0px 15px;
            
            h2{
                margin:0px;
                background:#1368b1;
                color:white;
                font-weight:bold;
                font-size:20px;
                line-height:33px;
                text-align:center;
                padding: 30px 20px;
            }
            
            p{
                font-size:16px;
                line-height:30px;
                color:black;
                padding:20px;
            }
        }
    }
    
    .placanje-row{
        .well{
            border:none;
            box-shadow:none;
            background:#F2F4F8;
            
            .col-md-3{
                border:none;
                font-size:20px;
                color:black;
                font-weight:bold;
                
                label{
                    margin-bottom: -2px;
                }
            }
            
            .col-md-12{
                border:none;
                font-size:16px;
                font-weight:normal;
                color:black;
                
                label{
                    margin-bottom: -2px;
                }
            }
        }
        .hmm{
            background:none;
        }
    }
    
    
    .rezervisi-row{
        .container{
            .well{
                border:none;
                box-shadow:none;
                background:#b6bfd3;
                height:70px;
                padding:0px;
                
                .row{
                    margin:0px;
                    height:100%;
                    button{
                        text-align:center;
                        background:#15bec5;
                        color:white;
                        font-weight:bold;
                        font-size:20px;
                        width:100%;
                        height:100%;
                        border:0px;
                    }
                }
             }
            
            p{
                color:black;
                font-weight:normal;
                font-size:16px;
                line-height:30px;
                padding:0px 20px 0px 20px;
            }
        }
        
    }    
}
