/*--------------------------------------------------------------
## App blade
--------------------------------------------------------------*/
#masthead {

	.branding-edit {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0;

		.site-branding {
			width: 25%;
			position: relative;
			display: flex;
			align-items: center;
		}

		#site-navigation {
			width: 75%;

			ul {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}
	}
	.container-head-edit {
		padding: 0;
	}
}

.top-nav-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;

	#top-navigation {
		width: 50%;
	}

	.top-meni-right {
		display: flex;
		align-items: center;
		width: 50%;
		justify-content: flex-end;
		position: relative;

		

		.dropdown-toggle-langs {
			background: $blue-dark;
		}

		span.language {
			display: flex;
			align-items: center;
			padding: 8px 35px 8px 10px;
			color: #fff;
			margin-right: 12px;

			&.lang-en {
				background: url(../images/flag-en.jpg) no-repeat right top 10px;
			}
			&.lang-de {
				background: url(../images/flag-de.jpg) no-repeat right top 10px;
			}
			&.lang-sr {
				background: url(../images/flag-rs.jpg) no-repeat right top 10px;
			}
		}

		ul.langs {
			position: absolute;
			top: 100%;
			right: 0;
			display: none;
			z-index: 9999;
			list-style: none;
			background-color: $blue-lighter;
			margin: 0;
			padding: 0;

			li {
				margin: 0;
				padding: 0;

				a {
					margin: 0;
					padding: 8px 60px 8px 20px;
					color: $white;
					display: block;

					&:hover {
						color: $blue;
						background-color: $white;
						text-decoration: none;
					}
				}
			}

			.lang-en {
				background: url(../images/flag-en.jpg) no-repeat right 20px top 11px;
			}
			.lang-de {
				background: url(../images/flag-de.jpg) no-repeat right 20px top 11px;
			}
			.lang-sr {
				background: url(../images/flag-rs.jpg) no-repeat right 20px top 11px;
			}
		}
	}

	.changesSlideDiv {
		position: absolute;
		top: 50%;
		padding: 24px;
		z-index: 999999;
		background: $submit-form;
		transform: translateY(33%);

		input {

			&:nth-child(2) {
				background: $blue;
			}
		}
	}
}

.home-slider-container {
	padding-bottom: 55px;
}

.site-footer {
	background: $footer;

	.copyright {
		background: $copyright;
		color: $white;
		padding: 15px;
		a {
			color: $blue-footer;
			transition: $dur $ease;
			&:hover {
				color: $blue;
			}
		}
	}

}

/****************responsive*******************/
@media only screen and (max-width: 1200px) {
	#masthead {

		.branding-edit {
			width: 100%;
			padding: 0 15px;
		}
		#site-navigation {

			a {
				max-width: 140px;
			}
		}
		.container-head-edit {
			width: 100%;
			padding: 0 15px;
		}
	}
}

@media only screen and (max-width: 1024px) {
	#masthead {

		.branding-edit {
			flex-direction: row-reverse;
			width: 100%;

			.collapsed-third {
				margin: 0;
				z-index: 9999;
			}
			.site-branding {
				width: 100%;
			}
			#site-navigation {
				width: 100%;
				top: 50px;

				ul {
					flex-direction: column;

					li {
						padding: 10px;
						width: 100%;
						display: flex;
						justify-content: center;

						a {
							width: auto;
							max-width: 100%;
						}
					}
					.login-btn {

						a {
							padding: 10px;
						}
					}
				}
			}
		}
		.container-head-edit {
			width: 100%;
			align-items: center;
			display: flex;

			.top-nav-wrapper {
				width: 100%;

				#top-navigation {
					width: auto;
				}
				.top-meni-right {
					width: auto;
				}
			}
		}
	}
	.home-slider-container {
		padding-bottom: 0;

		.bottom-banners {
			padding: 0 0 45px;
			margin-top: 45px;

			.slick-dotted {
				height: auto;
			}
		}
	}
}
@media only screen and (max-width: 767px) {
	#site-navigation {
		top: 63px;
	}
}
@media only screen and (max-width: 576px) {
	#masthead {

		.container-head-edit {

			.top-nav-wrapper {

				#top-navigation {
					display: none;
					position: absolute;
					background: $blue;
					width: 100%;
					left: 0px;
					top: 100%;
					z-index: 9999;

					.top-nav-mani-edit {
						display: flex;
						width: 70%;

						a {
							color: $white;
						}
					}
				}
				.collapsed-four {
					display: block;
				}
			}
		}
	}
}

.edit-bus-form {

	.col-md-6 {
		position: relative;


	}
}
.suggesstion-box {
  position: absolute;
  width: 100%;
  background: $sky-blue;
  z-index: 99999;

  p {
    padding: 5px;
    color: white;
    font-weight: bold;
  }
}
.alertValSpan {
	font-size: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin: 0;
}

.datepicker::-webkit-inner-spin-button,
.datepicker::-webkit-calendar-picker-indicator {
	display: none;
	-webkit-appearance: none;
}
.login-row {
	flex-direction: column;
}