

.fieldAlert {
  border: 1px solid red !important;
}

.popUpMsg{
  position:fixed;
  right:10px;
  bottom:10px;
  border-radius: 10px;
  padding: 5px;
  border: 1px solid #31708F;
  width:250px;
  z-index:1111;
  
}
/* INFO */
.popupInfo{
  background: #D9EDF7;
}
.popupInfo div{
  color: #31708F;
  text-align:center;
  font-weight: bold;
}
.popupInfo div h2{
  margin: 3px;
  font-size:20px;
}
.popupInfo p{
  color: #31708F;
  margin: 3px;
  text-align:center;
}



/* DANGER */
.popupDanger{
  background: #F2DEDE;
}
.popupDanger div{
  color: #A94442;
  text-align:center;
  font-weight: bold;
}
.popupDanger div h2{
  margin: 3px;
  font-size:20px;
}
.popupDanger p{
  color: #A94442;
  margin: 3px;
  text-align:center;
}




/* SUCCESS */
.popupSuccess{
  background: #DFF0D8;
}
.popupSuccess div{
  color: #3C763D;
  text-align:center;
  font-weight: bold;
}
.popupSuccess div h2{
  margin: 3px;
  font-size:20px;
}
.popupSuccess p{
  color: #3C763D;
  margin: 3px;
  text-align:center;
}