/*--------------------------------------------------------------
## Listwish
--------------------------------------------------------------*/
#listwish {
  display: none;
  width: 300px;
  background: $white;
  position: absolute;
  z-index: 9999;
  opacity: 0;
  top: 100px;
  min-height: 100vh;
  height: 100vh;
  overflow: auto;

  #content-listwish {
    display: none;
    padding: 0 20px;

    &.visible {
      display: block;
    }

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $blue-faded;
      padding: 15px 0;

      a {
        font-size: 13px;
      }

      .left {
        padding-left: 10px;

        i {
          font-size: 30px;
          color: $red;
        }
      }

      .right {
        width: 80%;
      }
    }
  }

  &.open {
    opacity: 1;
    top: 35px;
  }

  .wish-single {
    padding: 20px;
    display: flex;
    flex-flow: wrap;
    .right {
      width: 55%;
      margin-left: 3%;
      position: relative;

      .remove-wish {
        position: absolute;
        top: 0;
        right: -33px;

        i {
          font-size: 12px;
          color: $black;
        }
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-top: 5px;
      .bottom-left {
        margin-right: 5px;
        p {
          margin-bottom: 5px;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      .bottom-right {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .wish-price {
          color: $wish-price;
          font-weight: bold;
          font-size: 30px;
          text-align: right;
          line-height: 1;
        }
        .wish-price__currency {
          font-size: 26px;
          display: inline-block;
        }
      }
    }

  }
  .wish-single__image {
    display: block;
    img {
      height: 260px;
      width: 260px;
      object-fit: cover;
    }
  }
  .see-wish {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background: $blue;

    a {
      color: $white;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .spinner {
    display: none;
    margin: auto;
    width: 83px;
    height: 70px;
    text-align: center;
    font-size: 10px;
    padding: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    div {
      background-color: $blue-lighter;
      height: 100%;
      width: 6px;
      display: inline-block;

      -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
      animation: sk-stretchdelay 1.2s infinite ease-in-out;
    }

    .rect2 {
      -webkit-animation-delay: -1.1s;
      animation-delay: -1.1s;
    }

    .rect3 {
      -webkit-animation-delay: -1.0s;
      animation-delay: -1.0s;
    }

    .rect4 {
      -webkit-animation-delay: -0.9s;
      animation-delay: -0.9s;
    }

    .rect5 {
      -webkit-animation-delay: -0.8s;
      animation-delay: -0.8s;
    }

    &.active {
      display: block;
    }
  }

  //
  //&::after {
  //  content: "";
  //  display: block;
  //  width: 0;
  //  height: 0;
  //  border-left: 25px solid transparent;
  //  border-right: 25px solid transparent;
  //  border-bottom: 25px solid $white;
  //  position: absolute;
  //  top: 0;
  //  left: 50%;
  //  transform: translate(-50%,-50%);
  //  pointer-events: none;
  //  clear:both;
  //}

  .show-more {
    margin: 20px auto;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $sky-blue;
    border-radius: 30px;
    padding: 10px;
    color: $white;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
  }
}

#wishlist {
  position: relative;
  margin-left: 15px;
  &.active {

    &::after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-left: 25px solid transparent;
      border-right: 25px solid transparent;
      border-bottom: 25px solid $white;
      position: absolute;
      top: 45px;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
      clear: both;
    }
  }

}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4)
  }
  20% {
    -webkit-transform: scaleY(1.0)
  }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}

/*
 *  STYLE 5
 */

#listwish::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $white;
}

#listwish::-webkit-scrollbar {
  width: 10px;
  background-color: $white;
}

#listwish::-webkit-scrollbar-thumb {
  background-color: $scroll-wish;

  background-image: -webkit-gradient(linear, 0 0, 0 100%,
          color-stop(.5, rgba(255, 255, 255, .2)),
          color-stop(.5, transparent), to(transparent));
}


.wish-single__header {
  position: relative;
  width: 100%;
  p {
    font-weight: bold;
    color: $blue-dark;
    margin: 5px 0;
  }
  h2 {
    margin: 0;
    line-height: 1.3;
    font-size: 22px;
  }
  a {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 25px;
    height: 25px;
    i {
      position: absolute;
      right: 0;
      top: 7px;
    }
  }
}
.wish-content:last-of-type {
  margin-bottom: 50px;
}