.dashboard-item {
    position: relative;
    height: 75px;
    width: 85px;
    background-color: $blue-darker;
    ul {
        position: absolute;
        background-color: $blue-darker;
        top: 0;
        left: 0;
        transform: translateX(-100%);
        transition: $dur $ease;
        z-index: -1;
        width: 200px;
        height: auto;
        padding: 30px 15px;
        list-style: none;
        li {
            margin-bottom: 5px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        a {
            transition: $dur $ease;
            color: $sky-blue-light;
            font-size: 16px;
            &:hover {
                color: $brand-info;
            }
        }
    }
    &:hover {
        ul {
            transform: translateX(0%);
            left: 100%;
        }
    }
}
.dashboard-item-icon {
    position: absolute;
    height: 48px;
    width: 48px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-position: center;
    background-repeat: no-repeat;
    .not-mes-div {
        position: absolute;
        bottom: -10px;
        right: -10px;
    }
}
.dashboard-item-title {
    color: $white;
    font-size: 20px;
}

.select-box {
    padding: 10px;
    border: none;
    background-color: $white;
    height: 270px !important;
    width: 100%;
    text-align: left !important;
    text-align-last: left !important;
    option {
        padding: 5px 0;
    }
    &:focus {
        outline: none;
    }
}

.user-circle {
    width: 15px;
    height: 15px;
    background-color: grey;
    display: block;
    margin: 0 auto;
    border-radius: 50%;

    &.unconfirmed {
        background-color: #ffcb0f;
    }

    &.online {
        background-color: green;
    }

    &.blocked {
        background-color: red;
    }
}

.dashboard-text {
    font-size: 16px;
    color: #636b6f;
    max-width: 780px;
}