/*--------------------------------------------------------------
## singleBusOffersDetails
--------------------------------------------------------------*/
#myModal {

  .modal-header {
    border: none;
  }

  .modal-dialog {
    width: 100%;
    max-width: 1005px;

    #login-single {
      margin-top: 20px;

      form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        margin: auto;

        .form-row {
          display: flex;
          justify-content: flex-start;
          margin-bottom: 20px;

          .input-field {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
           


            input {
              width: 70%;
            }
          }
        }

        .submit-form {
          width: 100px;
          padding: 15px;
          border: 1px solid $sky-blue;
        }

        input {
          padding: 7px;
        }

        label {
          margin: 0;
        }
      }
    }

    #register-single {

      form {

        .column-wrapper {
          display: flex;
          justify-content: space-around;

          .column-2 {
            float: none;
            width: 50%;

            .input-field {
              display: flex;
              justify-content: space-between;
              width: 90%;
              margin-bottom: 20px;
              align-items: center;

              input {
                width: 70%;
              }

              .wrap-autocomplite {
                width: 70%;

                input {
                  width: 100%;
                }
              }
            }
          }
        }

        label {
          margin: 0;
        }
      }

      .submit-form {
        background: $sky-blue;
        width: 20%;

        &:hover {
          background: $white;
        }
      }
    }
  }
}