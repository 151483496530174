/*--------------------------------------------------------------
## archive-trans.blade
--------------------------------------------------------------*/
.archive-create-blade {

  .trans-filters {
    padding: 0;

    .form-filters {

      p {
        margin-bottom: 0;
        color: $white;
      }

      .input-range {
        display: flex;
        align-items: center;

        input {
          position: relative;
        }

        &::before {
          position: relative;
        }

        .slider-wrapper {
          padding: 0;
          margin-top: 0;
          width: 100%;
          display: flex;

          .slider-range {
            border: 2px solid $white;
            width: 90%;
            display: flex;
            align-items: center;

            span {
              background-color: #15bec5;
              outline: none;
              height: 15px;
              border-radius: 3px;
              width: 8px;
              margin-left: 0;
              margin-top: -3px;
            }
          }
        }
      }
    }

    .filter-title-btn {

      h2 {
        width: 100%;
      }
    }

    .see-all {

      padding: 8px;
      background: $blue-lighter;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $white;
      border-radius: 5px;
      margin-bottom: 10px;
    }

    .driver-english {

      &::before {
        width: 24px;
      }
    }
  }

  .trans-results {

    .single-trans {
      display: flex;

      .unformatted-list {
        margin-top: 0;
        margin-left: 5px;
        font-size: 1em;
        @include mq(767px) {
          padding: 0;
        }
      }

      .left-article {
        display: flex;
        flex-direction: column;
        width: 85%;

        .top {
          display: flex;
          padding: 20px;
          position: relative;
          height: 100%;

          .score {
            margin-bottom: 15px;
            /* position: absolute;
            right: 10px;
            top: 30px; */

            span {
              /* display: flex;
              justify-content: center;
              align-items: center; */
              display: inline-block;
              background: $sky-blue;
              text-transform: uppercase;
              color: $white;
              font-weight: bold;
              padding: 5px 8px;
              border-radius: 5px;
            }

            p {
              text-transform: uppercase;
              margin-top: 10px;
              font-style: italic;
              font-weight: bold;
            }
          }

          .wrap-left {
            width: 45%;
          }

          .right {
            width: 55%;
            padding: 0;
            padding-left: 15px;

            h1 {
              margin: 0 0 20px 0;
              font-weight: bold;
              color: $blue-dark;
              font-size: 30px;
            }

            .stars {
              display: flex;
              margin-bottom: 15px;

              i {
                font-size: 20px;
                color: $yellow;
                margin-right: 3px;
              }
            }

            .tour-content {

              .title {
                margin-bottom: 35px;
              }

              .box {
                display: flex;
                align-items: center;
                width: 80%;

                label {
                  margin: 0;
                }

                p {
                  margin: 0 0 0 10px;

                  img {
                    margin: 0 5px;
                  }
                }
              }
            }
          }
        }

        .left {
          width: 100%;
        }

        .info {
          margin-top: 10px;
          display: flex;

          p {
            margin: 0;
          }

          a {
            color: $blue-dark;
            font-weight: bold;
            margin-left: 5px;
          }
        }

        .bottom-info {
          background: #e2e5ed;
          align-items: center;
          display: flex;
          justify-content: space-between;
          padding-left: 25px;

          p {
            margin: 0;
            color: $blue-dark;
            font-weight: bold;
            font-size: 15px;
          }

          img {
            width: auto;
            height: 25px;
          }

          a {
            padding: 25px 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $blue-lighter;
            color: $white;
          }
          @include mq(767px) {
            flex-wrap: wrap;
            padding: 15px;
            .payment-methods {
              margin-bottom: 10px;
              img {
                margin-bottom: 5px;
              }
            }
            a {
              display: block;
              width: 100%;
              text-align: center;
            }
          }
        }
        @include mq(767px) {
          width: 100%;
          .top {
            flex-wrap: wrap;
            background-color: #eaeaea;
            .wrap-left {
              order: 2;
              width: 100%;
            }
            .right {
              width: 100%;
              padding-left: 0;
            }
            
          }
        }
      }

      .right-article {
        width: 15%;
        background: $blue-dark;
        display: flex;
        flex-direction: column;
        padding: 12px;
        padding-bottom: 90px;
        text-align: center;
        position: relative;

        p {
          margin: 0;
          font-size: 13px;
          color: $white;
        }

        label {
          color: $white;
        }

        span {
          color: $white;
        }

        .total {
          font-size: 30px;
          font-weight: bold;
          /* margin-top: 40px; */
          margin-bottom: 40px;
          display: flex;
          justify-content: center;
        }

        .top {
          color: $white;
          font-weight: bold;
          margin-bottom: 35px;


        }

        .bottom-rez {
          position: absolute;
          bottom: 0;
          padding: 25px;
          left: 0;
          width: 100%;
          background: $sky-blue;

          a {
            color: $white;
            text-transform: uppercase;
            font-weight: bold;
            display: flex;
            justify-content: center;
          }
        }
        @include mq(767px) {
          width: 100%;
          flex-direction: row;
          .top {
            margin-right: 15px;
            
            p {
              font-size: 20px;
            }
          }
          .center {
            margin-left: auto;
          }
        }
      }

      .bottom {
        background: #f2f4f8;

        img {
          width: 81px;
          height: 51px;
          margin: 0 10px 10px 0;
          @media screen and (max-width: 767px) {
            width: 100%;
            height: auto;
          }
        }
      }
      @include mq(767px) {
        flex-wrap: wrap;
      }
    }
    @include mq(767px) {
      padding: 35px 0;
    }
  }
}

.unformatted-list.unformatted-list {
  margin-top: 0;
}

.fancybox-navigation {
  display: block;
}


.trans-filters-edit {
  .input-range {
    position: relative;

    p {
      right: 7px !important;
    }
  }
}

.single-trans-detail {
  margin-bottom: 0;
  text-transform: uppercase;
  font-style: italic;
  text-align: right;
  font-weight: bold;
  @include mq(767px) {
    text-align: left;
  }
}

.tour-content-main {
  display: flex;
}