.dropdown {
	&:hover {
		.dropdown-menu {
			display: flex;
		}
	}
}
.dropdown-toggle {
	display: flex;
	align-items: center;
	border: 0;
	outline: 0;

	span.icon-caret-down {
		margin-top: -3px;
	}

	&:focus,
	&:active,
	&:active:focus {
		border: 0;
		outline: 0;
	}
}

.btn {
	padding: 7px 12px;
	z-index: 2;
	height: 35px;
	transition: $dur $ease;
}
.btn--blue {
	display: flex;
    width: 100%;
    cursor: pointer;
    background: $sky-blue !important;
    border-radius: 30px;
    text-transform: uppercase;
    font-weight: bold;
    justify-content: center;
    align-items: center;
	padding: 12px;
	transition: $dur $ease;
	border: 1px solid transparent !important;
	&.btn--blue {
		color: $white;
	}
	&:hover {
		color: $white !important;
	}
}
.btn--small {
	max-width: 200px;
}
.btn--right {
	margin: 0 30px 0 auto;
	transform: translateY(-30px);
	padding: 15px 75px;
}
.btn-wrap {
	height: 44px;
	display: flex;
	align-items: center;
	margin: 20px 0;
	.btn {
		position: relative;
	}
	@include mq(1200px) {
		height: auto;
	}
}
.btn-label {
	margin: 0 35px 0 0;
	font-size: 26px;
}

.btn-input-submit{
	cursor: pointer;
    background: $sky-blue !important;
    border-radius: 30px;
    color: $white !important;
	text-transform: uppercase;
	// width: 50%;
	min-width: 200px;
	padding: 12px;
	text-transform: uppercase;
	font-weight: bold;
	transition: $dur $ease;
	border: none;
	&:hover {
		background: $white !important;
		color: $sky-blue !important;
	}
}

.btn-caption {
	border-radius: 20px;
	background-color: #24bec4;
}