.ui-widget.ui-widget-content {
	border: 2px solid $submit-form;

	.ui-widget-header {
		background: $submit-form;
		color: $white;

		.ui-datepicker .ui-datepicker-prev {
			left: 1px;
			top: 1px;

			&:hover {
				background: $submit-form;
				color: $white;
			}
		}
	}
}
.login-modal {
	margin: 0 auto;
	@media screen and (max-width: 767px) {
		width: 100% !important;
	}
}
.js-login-warning {
	width: 520px !important;
	margin: 0 auto;
	@include mq(767px) {
		width: 100% !important;
	}
}
.login-modal-cont {
	display: flex;
	justify-content: center;
}