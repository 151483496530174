
.first-all-market{
    label{
        margin-left: -15px;
        padding-top: 20px;
        padding-bottom: 10px;
    }
}
$alphablack:rgba(0, 0, 0, 0.9);
.input-group[class*=col-] {
    @include mq(992px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}
.silver-tr{
    background:#CDD1DC;
    color:white;
    font-weight:bold;
    text-align:center;
}
.all-market {
    // overflow-y: scroll;
    .row {
        padding: 15px 0;
        .dot {
            background:#001F5D;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            display: inline-block;
            color: white;
            padding: 5px;
            font-weight: bold;
            font-size: 13px;
        }
        &:nth-child(even) {
            background-color: #e9f0fe;
            transition: $dur $ease;
            &:hover {
                background-color: $sky-blue-light;
                color: $white;
            }
        }
        &:nth-child(odd) {
            background-color: #dfe6f7;
            transition: $dur $ease;
            &:hover {
                background-color: $sky-blue-light;
                color: $white;
            }
        }
        .icon-info2 {
            display: inline-block;
            vertical-align: middle;
            // position: absolute;
            // right: 0;
            // top: 50%;
            // transform: translateY(-50%);
        }
        // a {
        //     border-radius:20px;
        //     background: #001F5D;
        //     color: white;
        //     border: none;
        //     padding-top: 7px;
        //     padding-right: 30px;
        //     padding-bottom: 7px;
        //     padding-left: 30px;
        // }
        .badge {
            border-radius: 5px;
            margin-left: 10px;
            background: #27bfc4;
            color: white;
            padding: 5px 20px 5px 20px;
        }
        .icon-info2 {
            margin-left: 5px;
        }
    }
    label {
        // margin-left: -15px;
        // padding-top: 20px;
        // padding-bottom: 10px;
    }
    &::-webkit-scrollbar {
        width: 15px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
        background: #e9f0fe; 
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #001F5D; 
        border-radius: 20px;
    }
    
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }
    @include mq(565px) {
        padding: 0;
    }
    
}

.market-wrap {
    background-color: $white;
    padding: 15px;
    margin: 15px 0;
    border-radius: 4px;
    .img-wrap {
        img {
            margin-bottom: 15px;
        }
    }
}
.market-subtitle {
    font-size: 26px;
}
.market-section-title {
    font-size: 26px;
    font-weight: 600;
    margin: 0;
}
.market-info {
    margin-left: 15px;
}
.market-items {
    display: flex;
    max-width: 640px;
    margin: 65px auto;
    justify-content: center;
    @include mq(640px) {
        flex-wrap: wrap;
        margin: 15px auto 30px;
    }
}
.market-item {
    width: 32%;
    text-align: center;
    @include mq(640px) {
        width: 100%;
    }
}
.market-item-title {
    display: block;
    margin-bottom: 15px;
}
.market-item-text {
    font-size: 26px;
    font-weight: 600;
}
.uploadImgWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include mq(640px) {
        
        img {
            display: block;
            margin: 15px auto;
        }
    }
}
.uploadImgText {
    display: block;
}
.uploadImg {
    max-width: 200px;
    width: 100%;
    max-height: 300px;
    
    @include mq(992px) {
        margin: 15px;
    }
}
.icon-remove {
    position: absolute;
    display: none;
    right: -15px;
    top: -5px;
    background: #15bec5;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    cursor: pointer;
    &::before {
        content: '';
        position: absolute;
        border: 1px solid white;
        width: 12px;
        top: 45%;
        left: 30%;
        transform: rotate(-50deg);
    }
    &::after {
        content: '';
        position: absolute;
        border: 1px solid white;
        width: 12px;
        top: 45%;
        left: 30%;
        transform: rotate(50deg);
    }
    @include mq(992px) {
        top: 0;
        right: -25px;
    }
}
.icon-remove--pdf {
    display: block;
    right: -40px;
    top: 0px;
}
.icon-show {
    display: block;
}

.hour-icon-wrapper {
    font-weight: 800;
    vertical-align: middle;
    text-align: center;
}
.datepicker--blue {
    background: #1468B1;
    color: $white !important;
    text-align: center !important;
    width: 100%;
    &::placeholder {
        color: $white;
    }
}
.market-item-total {
    transform: scale(1.2);
}


input[name='travel_plan'] {
    height: initial !important;
}

.offers-form-filter {
    padding: 10px 0;
    select {
        padding: 7px 5px;
        margin-right: 15px;
        border: none;
    }
    .select2-container {
        align-self: center;
        margin-right: 15px;
    }
    input {
        margin-right: 15px;
        padding: 5px 15px;
        border: 1px solid transparent;
        border-radius: 4px;
        transition: $dur $ease;
        &:focus {
            border: 1px solid $blue;
        }
    }
    .btn {
        line-height: 1;
    }
}
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    background-color: rgb(235, 235, 228);
}
.send-offer-btn-wrap {
    width: 100%;
    .ponuda {
        display: block;
        padding: 40px;
    }
}
.ponuda-istekla {
    background-color: $red !important;
    &:hover {
        cursor: not-allowed;
    }
}
.alert-custom {
    background-color: #1368b1;
    border-color: $sky-blue;
    color: $white;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.3px;
}