.terms-label {
    &::before {
        margin-left: -15px;
        margin-right: 55px;
    }
}
.terms-label-no-margin {
    width: 6px !important;
    &::before {
        margin-right: 0;
    }
}
.transfer-label-check {
    font-size: 20px;
    padding-left: 90px;
}
.transfer-title-wrap {
    display: flex;
    h2, h3 {
        margin-right: 20px;
    }
    .btn {
        margin-top: 18px;
        margin-bottom: 11px;
    }
}
.form-search-title {
    text-align: center;
    margin-left: 0 !important;
}
.form-search-wrapper {
    padding-left: 0;
}
.form-search-col {
    width: 50%;
    display: flex;
    .input-group-btn {
        position: relative;
    }
}
.form-search-row {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    #dani, #sati {
        background-color: #f6f6f6;
    }
    #dani {
        width: 200px;
    }
    @include mq(767px) {
        flex-wrap: wrap;
        padding: 0 15px;
        .form-search-input-wrap {
            justify-content: space-between;
            margin-bottom: 15px;
            label {
                display: block;
                margin-right: 15px;
                width: 100px;
            }
        }

    }
}
.form-search-input-wrap {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 350px;
    padding: 0 7.5px;
    .time-input {
        width: calc(100% - 100px);
        .input-group-btn {
            position: relative;
            left: initial;
            right: initial;
        }
    }
    select, > input {
        width: calc(100% - 100px);
        height: 34px;
    }
    label {
        margin-bottom: 0;
    }
}
.form-search-submit-wrap {
    display: flex;
    justify-content: center;
    width: 100%;
}
.submit-transfer-search-form {
    padding: 10px 50px !important;
    justify-self: center;
}
.centered {
    text-align: center;
}
.modal-window {
    display: none;
    position: fixed; /* Stay in place */
    z-index: 10; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
/* Modal Content/Box */
.modal-content {
    position: relative;
    display: flex;
    justify-content: center;
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
    max-width: 400px;
    select {
        background-color: $white;
        border: 2px solid $blue;
    }
}
/* The Close Button */
.close-modal {
    position: absolute;
    display: flex;
    justify-content: center;
    align-content: center;
    top: -20px;
    right: -20px;
    background-color: $blue;
    background: $blue;
    color: $white;
    font-size: 18px;
    font-weight: bold;
    border-radius: 50%;
    border: 1px solid transparent;
    height: 40px;
    width: 40px;
    transition: $dur $ease;
    opacity: 1;
}
.close-modal span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 24px;
    font-size: 20px;
    line-height: 1;
}
.close-modal:hover,
.close-modal:focus {
    color: $blue;
    text-decoration: none;
    background-color: $white;
    border: 1px solid $blue;
    cursor: pointer;
}
.body-block {
    overflow: hidden;
}

.transfer-wrap {
    .btn {
        margin-right: 15px;
        &:last-child {
            margin-right: 0;
        }
    }
}
.modal-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
}
.modal-wrap {
    flex-direction: column;
    max-width: 100%;
    width: 100%;
}
button.close-modal {
    background: #1368b1;
}
button.close-modal:hover {
    opacity: 1;
}