
.edit-tour-form {

	.row {
		margin-bottom: 10px;
	}

	/*
	 *
	 *
	 *  TITLE I SREDJIVANJE SLIKA ,
	 *  UBACIVANJE JEDNE SLIKE,
	 *  UBACIVANJE VISE SLIKA
	 *
	 *
	 *
	 *
	 */

	.singleImg {
		padding: 5px 0 0;
		margin-left: 15px;
		border-right: none;	
		text-align: center;
	}

	.title {
		h3 {
			font-size: 18px;
			margin-top: 5px;
			font-weight: bold;
			color: #333;
		}
	}



	.imgWrap {
		width: 100%;
		max-width: 100%;
		height: inherit;
		min-height: 170px;
		margin: 20px auto;
		background: #cdd1dc;
		position: relative;
		background: url('../images/emptyImg.gif');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		padding: 0px;

		img {
			width: 100%;
			height: initial;
		}

		.closeImg {
			position: absolute;
			border-radius: 50%;
			top: -12px;
			right: -12px;
			background: #15bec5;
			color: white;

			i {
				padding: 6px;

				&:hover {
					cursor: pointer;
				}
			}
		}
	}
	.titleImg {
		background: white;
		padding-bottom: 10px;
		min-height: 350px;
		// display: flex;
		// flex-wrap: wrap;

		h3 {
			font-size: 18px;
			font-weight: bold;
			color: #333;
		}
	}

	.gallery.button {
		margin-top: 15px;
	}

	.gallery-holder {
		margin-top: 20px;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;

		.multiImg {
			position: relative;
			flex: 0 0 22%;
			margin-right: 3%;
			padding-top: 22%;
			border: 1px solid #ccc;
			margin-bottom: 3%;

			.imgContainer {
				overflow: hidden;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background-size: cover;
				background-position: center center;
			}

			.deleteImg {
				position: absolute;
				border-radius: 50%;
				top: -12px;
				right: -12px;
				background: #15bec5;
				color: white;

				i {
					padding: 6px;

					&:hover {
						cursor: pointer;
					}
				}
			}
		}
	}

	/*
	 *
	 *
	 *
	 *
	 *
	 *
	 *
	 */

	.state-form {
		label {
			color: #24386d;
			margin-top: 10px;
			font-weight: 400;
		}
		button {
			background-color: #16bdc5;
			font-weight: bold;
			padding: 0px 10px 0px 10px;
			font-size: 20px;
			height: 36px;
		}
		[name='start_time'] {
			text-align: center;
		}
	}

	.nikolaFloatFirst {
		float: left;
	}
	.nikolaFloatFirst:nth-child(1) {
		width: 49%;
	}
	.nikolaFloatFirst:nth-child(2) {
		text-align: center;
		width: 17%;
	}
	.nikolaFloatFirst:nth-child(3) {
		text-align: center;
		width: 20%;
	}
	.nikolaFloatFirst:nth-child(4) {
		text-align: center;
		width: 4%;
	}
	.nikolaFloatFirst:nth-child(5) {
		text-align: center;
		width: 10%;
	}

	.nikolaFloat {
		float: left;
	}
	.nikolaFloat:nth-child(1) {
		width: 25%;
	}
	.nikolaFloat:nth-child(2) {
		text-align: center;
		width: 4%;
	}
	.nikolaFloat:nth-child(3) {
		text-align: center;
		width: 8%;
	}
	.nikolaFloat:nth-child(4) {
		text-align: center;
		width: 4%;
	}
	.nikolaFloat:nth-child(5) {
		text-align: center;
		width: 8%;
	}
	.nikolaFloat:nth-child(6) {
		text-align: center;
		width: 17%;
	}
	.nikolaFloat:nth-child(7) {
		text-align: center;
		width: 20%;
	}
	.nikolaFloat:nth-child(8) {
		text-align: center;
		width: 4%;
	}
	.nikolaFloat:nth-child(9) {
		text-align: center;
		width: 10%;
	}

	.nikolaFloatFour {
		float: left;
	}
	.nikolaFloatFour:nth-child(1) {
		width: 20%;
	}
	.nikolaFloatFour:nth-child(2) {
		text-align: center;
		width: 22%;
	}
	.nikolaFloatFour:nth-child(3) {
		text-align: center;
		width: 10%;
	}
	.nikolaFloatFour:nth-child(4) {
		text-align: center;
		width: 10%;
	}
	.nikolaFloatFour:nth-child(5) {
		text-align: center;
		width: 8%;
	}
	.nikolaFloatFour:nth-child(6) {
		text-align: center;
		width: 8%;
	}
	.nikolaFloatFour:nth-child(7) {
		text-align: center;
		width: 8%;
	}
	.nikolaFloatFour:nth-child(8) {
		text-align: center;
		width: 8%;
	}
	.nikolaFloatFour:nth-child(9) {
		text-align: center;
		width: 3%;
	}
	.nikolaFloatFour:nth-child(10) {
		text-align: center;
		width: 3%;
	}
}

.well-title {
	font-size: 18px;
    margin-top: 5px;
    font-weight: bold;
    color: #333;
}

.well-text-bold {
	font-size: 20px;
	font-weight: bold;
	color: $black;
}
.well-flex {
	display: inline-flex;
	label {
		margin-top: 9px;
		margin-right: 15px;
		margin-left: 10px;
	}
	input {
		width: 43px;
	}
}
.btn-blue {
	background-color: $sky-blue;
	color: $white;
	border-radius: 50px;
	width: 300px;
	text-align: center;
	text-transform: uppercase;
	height: initial;
	padding: 15px;
}