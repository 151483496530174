/*--------------------------------------------------------------
## Schedule reservation
--------------------------------------------------------------*/
.banner-schedule-reservation {
  position: relative;
  width: 100%;
  max-width: 1170px;
  margin: auto;
  padding: 0;

  p {
    font-weight: bold;
    font-size: 20px;
  }

  button {
    border-radius: 30px;
    background: #15bec5;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0%, -50%);
    text-transform: uppercase;
    padding: 15px 30px;
    border: none;
    font-weight: bold;
  }
}

.schedule-container {
    margin-top: -45px;
    
    .first-row{
        margin-bottom:20px;
        height:400px;
        .col-md-3{
            padding-right:0px;
        }
        .col-md-2{
            padding-left:0px;
            @include mq(991px) {
                padding-left:15px;
            }
        }
        img{
            width:100%;
            height:100%;
            @include mq(991px) {
                height:auto;
                margin-top:20px;
            }
        }
        .first-div{
            height:100%;
            @include mq(991px) {
                height:auto;
                padding-right:15px;
            }
            .well{
                margin-bottom:0px;
                height:100%;
                border:none;
                box-shadow:none;
                padding:20px;
                @include mq(991px) {
                    height:auto;
                }
                
                h2{
                    font-size:25px;
                    font-weight:bold;
                    margin-bottom:15px;
                    
                }
                
                .pull-right{
                    width: 100%;
                    background:#15BEC5;
                    color:white;
                    padding:5px 10px 5px 10px;
                    border-radius:5px;
                    float: initial !important;
                }
                
                p{
                    font-size:14px;
                    color:black;
                }
            }
        }
        .col-md-7{
            height:100%;
            @include mq(991px) {
                height:auto;
            }
        }
        .sec-div{
            height:100%;
            @include mq(991px) {
                height:auto;
            }
            .col-md-12{
                height:120px;
                padding:0px;
                @include mq(991px) {
                    padding:0px 15px 0px 15px;
                }
                
                &:nth-child(1){
                    margin-bottom:20px;
                }
                &:nth-child(2){
                    margin-bottom:20px;
                }
            }
            
        }
        
        @include mq(991px) {
            height:auto;
        }
    }
    
    
    .sec-row{
        margin-bottom:25px;
        
        .col-md-7{
            position:relative;
            #chartContainer{
                height:350px;
            }
            .arrowRight{
                position:absolute;
                z-index:9;
                top:50%;
                right:0px;
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent; 
                border-left:10px solid red; 
            }
            .arrowLeft{
                position:absolute;
                z-index:9;
                top:50%;
                left:0px;
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent; 
                border-right:10px solid red; 
            }
            
            @include mq(991px) {
                margin-bottom:20px;
                margin-top:20px;
            }
        }
        .col-md-5{
            padding-right:0px;
            overflow-y: scroll;
            height:350px;
            width:40.666667%;
            
            @include mq(991px) {
                width:100%;
            }
            
            &::-webkit-scrollbar {
                width: 10px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: #1368b1;
              outline: 1px solid #ccd1dc;
                border-radius:15px;
            }
            &::-webkit-scrollbar-track-piece  { 
                   background-color: #ccd1dc;
            }
            
            label{
                margin-bottom:5px;
                
                i{
                    &:nth-child(1){
                        margin-left:20px;
                    }
                }
            }
            .well{
                background:#f2f4f8;
                border:0px;
                margin-bottom:0px;
                .row{
                    height:100%;
                    .col-md-3{
                        padding:0px; 
                        padding-left:15px;
                        width:18%;
                    }
                    .col-md-7{
                        padding-right:0px;
                        p{
                            font-size:14px;
                            line-height:26px;
                        }
                        label{
                            font-size:18px;
                            line-height:33px;
                            font-weight:bold;
                        }
                    }
                    
                    .sec{
                        position:relative;
                        padding:0px;
                        height:100%;
                        text-align:right;
                        
                        p{
                            position:absolute;
                            width:70px;
                            top:50%;
                            left:50%;
                            transform:translate(-50%,-50%);
                            color:#011f5d;
                            font-weight:bolder;
                            margin-top:80%;
                            font-size:30px;
                            font-weight:bold;
                            
                            @include mq(768px) {
                                position:relative;
                                width:57px;
                                margin:0px;
                            }
                        }
                    }
                }
            }
        }
    }
    
    .shop{
        margin-bottom:25px;
        padding-left: 15px;
        padding-right:15px;
        
        .well{
            background:#f2f4f8;
            border:0px;
            box-shadow:none;
            padding:10px 0 0px 15px;
            position: relative;
            
            .right{
                p{
                    color:white;
                    font-weight:bold;
                    font-size:13px;
                    line-height:12px;  
                    margin-top:21px;  
                    
                    span{
                        font-weight:normal;
                        line-height:15px;  
                    }
                    
                    @include mq(991px) {
                        width:100%;
                        padding-top:20px;
                        margin:0px;
                    }
                }
                h1{
                    font-size:30px;
                    line-height:25px; 
                    color:white;
                    font-weight:bold;
                    margin-top:75px;  
                    @include mq(991px) {
                        margin-top:40px;
                    }
                }
                .setajuci{
                    position:absolute;
                    bottom:0px;
                    left:0px;
                    width:100%;
                    
                    p{
                        color:white;
                        font-weight:bold;
                        font-size:13px;
                        line-height:12px;  
                    }
                    span{
                        font-weight:normal;
                        line-height:15px; 
                        font-size:13px; 
                        color:white;
                    }
                }
            }
            
            
            .images{
                .col-md-12{
                    padding-top:0px;
                    img{
                        width: 100%;
                        height: 180px;
                    }
                }
                .float{
                    width:30%;
                    float:left;
                    margin-right:5%;
                    img{
                        margin-top:10px;
                        height:70px;
                        width:100%;
                    }
                    &:nth-child(4){
                       margin-right:0px;
                    }
                    
                }
                .clear{
                    clear:both;
                }
            }
            
            .body{
                p{
                    font-size:13px;
                    color:#888888;
                    margin-bottom: 2px;
                }
                label {
                    font-size: 13px;
                }
                .col-md-5{
                    h1{
                        font-size:30px;
                        line-height:25px;
                        font-weight:bold;
                        color:#001f5d;
                    }
                    i{
                        margin-right:15px;
                        font-size:18px;
                        color:#ffc731;
                        margin-bottom:15px;
                    }
                    
                    .top-margin{
                        margin-top:24px;
                    }
                }
                
                .col-md-4{
                    .first-p{
                        margin-top:95px;
                    }
                }
                .col-md-3{
                    padding-top:20px;
                    padding-right:20px;
                    label{
                        background:#15bec5;
                        padding:5px 15px 5px 15px;
                    }
                }
            }
            
            .col-md-12{
                background:#e2e5ed;
                padding:0px;
                padding-top:20px;
                margin:0px;
                margin-top:15px;
                margin-right: 15px;
                .col-md-6{
                    p{
                        margin-top:5px;
                    }
                }
                .col-md-5{
                    padding-right:40px;
                    
                    span{
                        margin-right:5px;
                        margin-left:3px;
                        color:#1368b1;
                        font-weight:bold;
                    }
                }
            }
        }

        .trailers {
            margin-top: 20px;

            label {
                padding-top: 4px;

                &:before {
                    left: 0;
                }
            }

            ul {
                margin: 10px 0;
                padding: 0;
                list-style-type: none;
            }
        }
    }
    
    .shop2{
        margin-bottom:25px;
        padding-left: 15px;
        padding-right:15px;
        
        .well{
            background:#f2f4f8;
            border:0px;
            box-shadow:none;
            padding:10px 15px 0px 15px;
            position: relative;
            
            .images{
                .col-md-12{
                    padding-top:0px;
                    img{
                        width: 100%;
                        height: 180px;
                    }
                }
            }
            
            .body{
                .col-md-5{
                    h1{
                        font-size:30px;
                        line-height:25px;
                        font-weight:bold;
                        color:#001f5d;
                        
                    }
                    i{
                        margin-right:15px;
                        font-size:18px;
                        color:#ffc731;
                        margin-bottom:15px;
                    }
                    p{
                        font-size:13px;
                        color:#888888;
                        margin-bottom: 10px;
                    }
                    .top-margin{
                        margin-top:24px;
                    }
                }
                
                .col-md-4{
                    p{
                        font-size:13px;
                        color:#888888;
                    }
                    .first-p{
                        margin-top:65px;
                        
                        @include mq(991px) {
                            margin-top:15px;
                        }
                    }
                }
                .col-md-3{
                    padding-top:20px;
                    padding-right:20px;
                    label{
                        background:#15bec5;
                        padding:5px 15px 5px 15px;
                    }
                }
            }
            
            .col-md-12{
                background:#e2e5ed;
                padding:0px;
                padding-top:20px;
                margin:0px;
                margin-top:15px;
                position:relative;
                    
                .col-md-6{
                    p{
                        margin-top:5px;
                    }
                }
                .col-md-5{
                    padding-right:40px;
                    
                    span{
                        margin-right:5px;
                        margin-left:3px;
                        color:#1368b1;
                        font-weight:bold;
                    }
                    input{
                        width:50px;
                        background:#1368b1;
                        color:white;
                    }
                    @include mq(991px) {
                        text-align:left;
                    }
                }
                button{
                    margin-left:20px;
                    width:120px;
                    height:100%;
                    color:white;
                    background:#1368b1;
                    position:absolute;
                    top:0px;
                    right:0px;
                    font-size:16px;
                    line-height:25px;
                    font-weight:bold;
                }
            }
        }
    }
    
    .uslovi{
        .well{
            height:400px;
            background:#F2F4F8;
            border:none;
            box-shadow:none;
        }
    }
    
    .nine-row {
        table {
            margin:0px;
            thead {
                background: #1670be;
                font-size: 20px;
                font-weight: bold;
                color: white;
                
                th {
                    text-align: center;
                    border: 0px;

                    &:nth-of-type(1) {
                        width: 70%;
                        text-align: left;
                        padding-left:100px !important;
                    }
                    &:nth-of-type(2) {
                        width: 30%;
                    }
                }
            }

            tbody {

                tr td {
                    text-align: center;
                    border: 0px;
                    font-weight: bold;
                    color: black;
                    font-size: 18px;
                    padding:20px;
                    
                    &:nth-of-type(1){
                        text-align: left;
                        padding-left:100px !important;
                    }
                }

            }
        }
        
        .well{
            background:#dfe3ea;
            border:none;
            box-shadow:none;
            padding:0px;
            
            
            .mojaPrva{
                background:#dfe3ea;
                width:100%;
                padding:20px;
                label{
                    font-size:20px;
                    line-height:25px;
                    color:#333333;
                    padding-right:140px;
                    
                    span{
                        font-size:42px;
                        line-height:35px;
                    }
                }
            }
            .moja{
                background:#15bec5;
                width:100%;
                padding:20px;
                h2{
                    margin:0px;
                    font-size:20px;
                    line-height:25px;
                    font-weight:bold;
                    text-align:center;
                }
            }
        }
        input {
            background: none !important;
        }
    }
    
    .wwwbtn{
        width:100% !important;
        height:100px;
        font-size:20px;
        line-height:25px;
        font-weight:bold;
        
        &:hover{
            background:#1670BE;
            color:white;
        }
    }
    
    
}

.schedule-item {
    position: relative;
    margin-bottom: 25px;
}
.schedule-body {
    display: flex;
    @include mq(767px) {
        flex-wrap: wrap;
    }
}
.schedule-body-img {
    max-width: 270px;
    width: 26%;
}
.schedule-body-img-main {
    margin-bottom: 15px;
}
.schedule-body-img-imgs {
    display: flex;
}

.schedule-header {
    background-color: $blue;
    color: $white;
    padding:19px 0;
    z-index: 10;

    .schedule-selection {
        color: $white;
    }
}

.schedule-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px 0 0 15px;
    padding: 70px 0;
    top: 69px;
    height: 90%;
    background: #001f5d;
    right: 0px;
    position: absolute;
    width: 10%;
    text-align:center;
    
    @include mq(991px) {
        position:relative;
        width:100%;
        margin:0px;
        margin-top:70px;
    }
}

.schedule-info-title {
    margin-bottom: 65px;
    font-weight: bold;
    @include mq(767px) {
        margin-bottom: 15px;
    }
}

.schedule-info-footer {
    margin-top: auto;
}
.schedule-body-title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.schedule-body-content {
    width: 74%;
    padding: 0 15px;

    .bus-equipment-list {
        cursor: pointer;
    }
}
.schedule-body-title {
    font-size: 30px;
    line-height: 25px;
    font-weight: bold;
    color: #001f5d;
}
.schedule-body-mark {
    label {
        font-size: 13px;
        background: #15bec5;
        padding: 5px 15px 5px 15px;
    }
}

.schedule-body-stars {
    i {
        margin-right: 2px;
        font-size: 20px;
        color: $yellow;
        margin-bottom: 15px;
    }
}
.schedule-body-content-text {
    display: flex;
    justify-content: space-between;
    > div {
        flex-basis: 49%;
    }
    p {
        font-size: 13px;
        color: #888888;
        margin-bottom: 2px;
    }
    label {
        font-size: 13px;
    }
}

.schedule-footer {
    // position: absolute;
    // bottom: 0;
    // left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e2e5ed;
    //width: calc(100% - 125px);
    .form-group {
        margin: 15px;
    }
}

.schedule-body-img-small {
    max-width: 33%;
    margin-right: 1%;
}

.schedule-footer-text {
    padding-left: 15px;
    align-self: center;
    font-weight: bold;
    margin-right: auto;
    margin-left: 0;
    @include mq(767px) {
        margin-right: 15px;
    }
}

.schedule-footer-inputs {
    span {
        color: $blue;
        font-weight: bold;
    }
    @include mq(767px) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        span, input, select {
            width: 45%;
            
        }
        input, select {
            margin: 5px !important;
            padding: 5px !important;
        }
    }
}

.schedule-footer-tooltip {
    font-size: 1.2em;
    line-height: 1em;
}

.schedule-body-side {
    display: flex;
    flex: 0 0 125px;
    flex-direction: column;
    justify-content: center;
    background-color: $blue-dark;
    color: $white;
    max-width: 125px;
    width: 100%;
    text-align: center;
    padding: 25px 10px;
    p {
        font-size: 13px;
    }
    @include mq(767px) {
        max-width: 100%;
        flex-basis: 100%;
    }
}
.schedule-accordion {
    position: relative;
}
.schedule-accordion-big {
    .schedule-accordion__head {
        background-color: #15bec5;
    }
    .trailer-selection {
        font-size: 25px;
        font-weight: bold;
        color: $white;
        cursor: pointer;
    }
    .label-check:before {
        top: 8px;
    }
}
.schedule-accordion--empty {
    .schedule-trailer {
        margin-top: 60px;
    }
}

.schedule-trailer {
    display: flex;
    position: relative;
    .schedule-footer {
        width: 100%;
    }
    @include mq(767px) {
        flex-wrap: wrap;
    }
}

.schedule-trailer__img {
    max-width: 270px;
    width: 100%;
    //margin-bottom: 80px;
    @include mq(767px) {
        margin-bottom: 15px;
    }
}
.schedule-trailer-content {
    display: flex;
    padding: 15px;
}

.schedule-trailer-content-left {
    margin-right: 20px;
}





.schedule-bus-info {
    padding: 20px 0;
    a {
        font-weight: bold;
    }
}
.schedule-accordion__head {
    display: flex;
    justify-content: space-between;
    background-color: #e2e5ed;
    color: #001f5d;
    padding: 25px 20px;
    /* &:hover {
        cursor: pointer;
    } */
}
.schedule-accordion__head-icon {
    align-self: center;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7.5px 0 7.5px 15px;
    border-color: transparent transparent transparent $black;
    transition: $dur $ease;
    cursor: pointer;
}
.schedule-accordion__head-icon--active {
    transform: rotate(90deg);
}
.schedule-accordion__body {
    display: none;
}
.schedule-equipment {
    display: flex;
    margin: 0;
    margin-bottom: 64px;
    padding: 25px 0;
    flex-wrap: wrap;
    list-style: none;
    li {
        margin: 0 10px 10px 10px;
        max-width: 140px;
        width: 100%;
        line-height: 1.2;
        &:first-letter {
            text-transform: uppercase;
        }
    }
}

.schedule-body-wrap {
    display: flex;
    flex-wrap: wrap;
}

.schedule-body-footer {
    width: 100%;
    margin-top:20px;
    background-color:#e2e5ed;
    padding:10px;
    display: flex;
    justify-content: space-between;
}

.schedule-totals {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    margin: 0 auto;
    transform: translate(0, 100%);
    transition: 500ms;
    
    tbody {
        background-color: #f9f9f9;
    }

    &.show, 
    &.relative {
        transform: translate(0);
    }

    &.relative {
        margin-bottom: 30px;
        
        .schedule-totals-btn {
            display: none;
        }
        .container {
            padding: 0;
            width: 100%;
        }
    }

    .well {
        margin-bottom: 0;
    }
}

.schedule-totals-btn {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    transform: translate(0, -100%);
    padding: 30px;
    background-color: #1d71bc;
    color: #fff;
    margin-left: 15px;
    transition: 500ms;
    font-weight: bold;
    font-size: 25px;
    
    &:hover {
        color: #1d71bc;
        background-color: #fff;
    }
}

.schedule-footer-ro-input.schedule-footer-ro-input {
    width: 40px;
    padding: 6px;
    text-align: center;
    background-color: $blue;
    color: $white;
}
.schedule-img {
    // position: relative;
    // img {
    //     max-width: 100%;
    //     display: block;
    //     height: initial;
    //     vertical-align: middle;
    // }
}
