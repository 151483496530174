.btn-bs-file{
    position:relative;
    background: #15BEC5;
    color: white !important;
    border-radius: 75px 75px 75px 75px;
    padding: 9px 30px 9px 30px !important;
    font-size: 14px !important;
    font-weight: bold;
    cursor: pointer !important;
    
    &:hover {
        background: #15BEC5;
        color: white;
        border-color: #7da8c3;
    }
}
.btn-group-lg>.btn, .btn-lg{
   font-size: 13px;
   cursor:pointer;
}
.btn-bs-file input[type="file"]{
    position: absolute;
    top: -9999999;
    filter: alpha(opacity=0);
    opacity: 0;
    width:0;
    height:0;
    outline: none;
}

.uploading-process {
    visibility: hidden;
    position: absolute;
    top: 0;
    margin: 0 auto;
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
}
.uploading-process__img {
    width: 25px;
    height: 25px;
}