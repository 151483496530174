.site-main {
    margin-top: 40px;
}

.alertValSpan {
    color: red;
    display: block;
    bottom: -20px;
    left: 50%;
    text-align: center;
    font-weight: bold;
}

.alert-fixed {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 20%;
    z-index: 99999999;
    @media screen and (max-width: 767px) {
        max-width: 300px;
        width: 100%;
    }
}

.alert-close {
    position: absolute;
    top: -5px;
    left: -5px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background: red;
}

.alert-fixed-wrap {
    position: relative;
    border-radius: 20px;
    background: #1368B1;
    padding: 20px;
    margin-bottom: 10px;
    cursor: pointer;
}

.alert-fixed-wrap p {
    text-align: left;
    margin: 0px;
    color: white;
}

.tooltipGlo {
    position: relative;
    cursor: pointer;
}

.tooltiptext {
    display: none;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    // bottom: 150%;
    left: 50%;
    // margin-left: -60px;
    font-family: "Roboto , snas-serif";
    font-size: 13px !important;
    font-weight: bold;
    padding: 10px;
    z-index: 9999;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    hyphens: auto !important;
    // transform: translateX(50%);
}

.tooltiptext::after {
    display: none;
}

.tooltiptext-left {
    transform: translate(-95%, -100%) !important;

    &::after {
        left: 95%;
    }
}

.tooltiptext-right {
    transform: translate(-5%, -100%) !important;

    &::after {
        left: 5%;
    }
}

.well.well-white.well-white.well-white {
    background-color: #fff;
    padding: 30px;
}

.buses-checkbox {
    cursor: pointer;
}

.p-no-margin {
    margin-bottom: 0;
}

.cust-html-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: default;
    color: $white;
    font-weight: bold;
    font-size: 26px;
}

.login-modal.login-modal {

    .ui-widget-header.ui-widget-header {
        background-color: #1a69af;
    }
}

.login-modal-cont {
    display: flex;
    flex-wrap: wrap;
}

.modal-btn.modal-btn.modal-btn {
    background-color: #15bec5;
    border-radius: 30px;
    padding: 10px;
    color: $white;
    margin: 5px;
    border: 1px solid #15bec5;
    text-decoration: none;
    font-weight: bold;

    &:hover {
        background-color: $white;
        color: #15bec5;
    }
}

.ui-widget-overlay {
    opacity: 0.5;
}

.bus-preview-new p b {
    color: #565656;
}

@-moz-document url-prefix() {
    .transfer-berza__time {
        input {
            width: 70%;
        }
        button {
            width: 33px;
            margin-right: 0;
        }
    }
} 
.carousel-control {
    @media screen and (max-width: 767px) {
        background-color: red !important;
        top: 50%;
        bottom: initial;
        transform: translateY(-50%);
        &.left {
            left: -15px;
            >span {
                margin-left: -30px;
            }
        }
        &.right {
            right: -15px;
            >span {
                margin-right: -30px;
            }
        }
    }
}