/*--------------------------------------------------------------
## Checkbox
--------------------------------------------------------------*/
.buses-checkbox::before {
	content: '';
	flex: 0 0 19px;
	margin-right: 10px;
	display: block;
	width: 19px;
	height: 19px;
	background: white;
	border-radius: 3px;
	z-index: 1;
	border: 1px solid $checkbox-radio;
	cursor: pointer;
}
.label-check{
  padding-left: 40px;
}

input[type="checkbox"] {
	display: none;

	&:checked + label::before {
		background: $checkbox-radio;
		border: 1px solid $white;
	}
}

input[type="radio"] {
	display: none;

	&:checked + label::before {
		background: $checkbox-radio;
		border: 1px solid $white;
	}
}

input[type="radio"] {
	display: none;

	&:checked + .optionIsSelect::before {
		background: $white;
		border: 1px solid $white;
	}
}

input[type="radio"] {
	display: none;

	&:checked + .aeroIsChecked::before {
		background: $white;
		border: 1px solid $white;
	}
}

.optionSelect::before {
	background: $blue;
	border: 1px solid $white;
}

.label-check-single {
	padding-left: 50px;
}
.radio-edit {
	label {
		input {
			display: block;
			
		}
	}
}